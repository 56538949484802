<section class="page-question-page page-content-container">
  <div class="delegation-info" *ngIf="userAccessDetails?.caseRelatedAccess.onBehalfOfMessage && questionsData?.page_group_name === PAGE_GROUP_NAME.POLICY_INFO_PAGE">
    <h1>
        On Behalf of Disclaimer:
    </h1>
    <lfg-alert-message type="info">{{delegateMsg}}</lfg-alert-message>
  </div>

  <div class="questions--container">
    <div class="loader-container" *ngIf="showLoader">
      <lfg-loader></lfg-loader>
    </div>
    <h1 *ngIf="questionsData?.page_group_name === PAGE_GROUP_NAME.PRIMARY_INSURED_PAGE">
      Proposed Insured Information
    </h1>
    <h1 *ngIf="questionsData?.page_group_name === PAGE_GROUP_NAME.THIRD_PARTY_DESIGNEE_PAGE">
      Protection Against Unintended Lapse
    </h1>
    <h1 *ngIf="questionsData?.page_group_name === PAGE_GROUP_NAME.TEMPORARY_LIFE_INSURANCE_PAGE">
      Temporary Insurance Agreement
    </h1>
    <h1 *ngIf="questionsData?.page_group_name === PAGE_GROUP_NAME.LIFE_SUITABILITY_PAGE">
      Life Insurance Suitability
    </h1>
    <h1 *ngIf="questionsData?.page_group_name === PAGE_GROUP_NAME.AGENT_REPORT_PAGE">
      Agent Report
    </h1>
    <h1 *ngIf="questionsData?.page_group_name === PAGE_GROUP_NAME.LTC_SUITABILITY_PAGE">
      LTC Suitability
    </h1>
    <h1 *ngIf="questionsData?.page_group_name === PAGE_GROUP_NAME.POLICY_INFO_PAGE">
      Policy Information
    </h1>
    <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>
    <section>
      <form class="form-group" [formGroup]="pageQuestionForm" id="pageQuestionForm" method="post" *ngIf="pageQuestionData && pageQuestionData.length > 0">
        <ng-container class="form-control-parent" *ngFor="let data of pageQuestionData | dataFilter: null: null: questionsData?.page_group_name; let ind = index;" >

          <div class="section-divider required" *ngIf="data.questionText == 'Are you a member of, have you entered into a written agreement to become a member of, or have you received a notice of required service in the armed forces, reserves or National Guard?'"></div>

          <div class="section-divider required" *ngIf="data.xmlTag === '/XML/ApplicationDetail/ProtectionAgainstUnintendedLapseInd'"></div>

          <!-- element to switch controls to new line -->
          <div class="full-width-ele" *ngIf="pushFieldToNewLine.indexOf((data.questionText).toLowerCase()) !== -1">
          </div>

          <!-- Questions headers -->
          <div class="questions-header" *ngIf="questionsData?.page_group_name === PAGE_GROUP_NAME.LTC_SUITABILITY_PAGE && ind === 0"
            [innerHTML]="questionsData?.pages[0].name">
          </div>

          <!-- Questions headers -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR  && data.questionText.indexOf('Agent Acknowledgement') === -1 && data.questionText != 'Line' && data.subText != 'Information'"
            [innerHTML]="data.questionText">
          </div>

           <!-- Questions headers -->
           <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText.indexOf('Agent Acknowledgement') > -1 && declineDisclosure && data.questionText != 'Line' && data.subText != 'Information'"
           [innerHTML]="data.questionText">
         </div>

          <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
          </div>

          <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
          <div class="questionText" *ngIf="addCorrectPlaceHolder(data, 'display')  && data.xmlTag !== agentReportKeys.caseManagerFirstName && data.xmlTag !== agentReportKeys.caseManagerLastName && data.xmlTag !== agentReportKeys.caseManagerPhoneNumber && data.xmlTag !== '/XML/MGeAPP/Riders/LTCABRBaseMonthlyInflation'">
            <span [innerHTML]="addCorrectPlaceHolder(data, 'question')"></span>
          </div>

          <div class="questionText" *ngIf="addCorrectPlaceHolder(data, 'display') && data.xmlTag === '/XML/MGeAPP/Riders/LTCABRBaseMonthlyInflation' && checkProtectionOption()">
            <span [innerHTML]="addCorrectPlaceHolder(data, 'question')"></span>
          </div>

          <div class="questionText" *ngIf="addCorrectPlaceHolder(data, 'display') && (data.xmlTag == agentReportKeys.caseManagerFirstName || data.xmlTag == agentReportKeys.caseManagerLastName || data.xmlTag == agentReportKeys.caseManagerPhoneNumber) && doYouAttestIndFieldIdValue != 'i attest'">
            <span [innerHTML]="addCorrectPlaceHolder(data, 'question')"></span>
          </div>

          <!-- USA address -->
          <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
            <h2>{{ data.questionText }}</h2>
          </div>

          <!-- Form element for text  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.xmlTag !== '/XML/Client/AgentDetails/CaseManagerFirstName' && data.xmlTag !== '/XML/Client/AgentDetails/CaseManagerLastName' && (data.notes !== 'ITES_FORMAT_AS_ZIPCODE' && data.notes !== 'ITES_FORMAT_AS_CURRENCY')" [ngClass]="{'resetError': data.xmlTag == '/XML/Client/AppDetails/DriverLicenseNum' && !pageQuestionForm.get(data.fieldId).errors}">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' customAttr="text" formControlName='{{ data.fieldId }}' autocomplete="off" name="" appNoSpecialSymbols [maxlength]="data.maxLength >= 999 ? 30 : data.maxLength" [required]="data.required == 'true'">

              <lfg-error *ngIf="pageQuestionForm.get(data.fieldId).touched && pageQuestionForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for text  -->
          <div class="form--control" *ngIf="((data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.xmlTag === '/XML/Client/AgentDetails/CaseManagerFirstName') || (data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.xmlTag === '/XML/Client/AgentDetails/CaseManagerLastName')) && doYouAttestIndFieldIdValue !== 'i attest' && (data.notes !== 'ITES_FORMAT_AS_ZIPCODE' && data.notes !== 'ITES_FORMAT_AS_CURRENCY')" [ngClass]="{'resetError': data.xmlTag == '/XML/Client/AppDetails/DriverLicenseNum' && !pageQuestionForm.get(data.fieldId).errors}">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' customAttr="text" formControlName='{{ data.fieldId }}' autocomplete="off" name="" appNoSpecialSymbols [maxlength]="data.maxLength >= 999 ? 30 : data.maxLength" [required]="data.required == 'true'">

              <lfg-error *ngIf="pageQuestionForm.get(data.fieldId).touched && pageQuestionForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

           <!-- Form element for zip  -->
          <div class="form--control" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.TEXT && data.notes === 'ITES_FORMAT_AS_ZIPCODE'">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" mask='00000' id="{{data.fieldId}}"
                placeholder="{{ utilsService.getCorrectContent(data, 'placeHolder') }}" appNoSpecialSymbols
                type='text' [formControl]='pageQuestionForm.get(data.fieldId)'
                autocomplete="off" appValidateOnBlur value="" maxlength='5'
                [ngClass]="{'inp-txt--err': (pageQuestionForm.get(data.fieldId).touched && pageQuestionForm.get(data.fieldId).invalid) }"
                [required]="data.required === 'true'">

              <lfg-error
                *ngIf="pageQuestionForm.get(data.fieldId).touched && pageQuestionForm.get(data.fieldId).invalid"
                message="{{ getErrorMsg(data) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for SSN -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SSN">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" mask='AAA-AA-0000' [formControl]='pageQuestionForm.get(data.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur maxlength='11' [required]="data.required == 'true'">

              <lfg-error *ngIf="(pageQuestionForm.get(data.fieldId).touched && pageQuestionForm.get(data.fieldId).invalid) && ( pageQuestionForm.get(data.fieldId).errors == null || (pageQuestionForm.get(data.fieldId).errors != null && !pageQuestionForm.get(data.fieldId).errors.notUnique))" message="Please enter a valid SSN."></lfg-error>

              <lfg-error *ngIf="(pageQuestionForm.get(data.fieldId).invalid && pageQuestionForm.get(data.fieldId).touched) &&(pageQuestionForm.get(data.fieldId).errors != null && pageQuestionForm.get(data.fieldId).errors.notUnique)" message="This SSN has already been provided, please review and edit"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for radio question -->
          <div class="lfg-customRadio-field radio-btn-custom" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.xmlTag !== '/XML/Client/AgentDetails/DoYouAttestInd'"
            [ngClass]="{'radio--err': (pageQuestionForm.get(data.fieldId).touched && pageQuestionForm.get(data.fieldId).invalid),
            'question-radio--small': data.xmlTag == '/XML/Client/CRMDetails/Gender', 'question-radio--medium': data.xmlTag == '/XML/Client/CRMDetails/PrimaryPhoneType'
            || data.xmlTag == '/XML/Client/CRMDetails/SecondaryPhoneType', 'radio-header': data.xmlTag == '/XML/Client/AppDetails/DriverLicenseNoneChecked' ||
            data.xmlTag == '/XML/Client/CRMDetails/USCitizenInd'}">
            <span class="lfg-radio-section">
              <p class="question-radio_text">{{ data.questionText + (data.required == 'true' ? '' : ' (optional)') }}</p>
              <div class="custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onRadioChange(data)"/>
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input *ngIf="data.questionOption[1]" [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onRadioChange(data)">
                <label *ngIf="data.questionOption[1]" for="no_{{ data?.fieldId }}">{{ data.questionOption[1]?.description }}</label>
              </div>
            </span>
            <lfg-error *ngIf="pageQuestionForm.get(data.fieldId).touched && pageQuestionForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data) }}"></lfg-error>
          </div>

          <!-- Form element for radio question -->
          <div class="lfg-customRadio-field radio-btn-custom" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO && data.xmlTag === '/XML/Client/AgentDetails/DoYouAttestInd' && caseManagerEmail && primaryAgentEmail === caseManagerEmail"
            [ngClass]="{'radio--err': (pageQuestionForm.get(data.fieldId).touched && pageQuestionForm.get(data.fieldId).invalid),
            'question-radio--small': data.xmlTag == '/XML/Client/CRMDetails/Gender', 'question-radio--medium': data.xmlTag == '/XML/Client/CRMDetails/PrimaryPhoneType'
            || data.xmlTag == '/XML/Client/CRMDetails/SecondaryPhoneType', 'radio-header': data.xmlTag == '/XML/Client/AppDetails/DriverLicenseNoneChecked' ||
            data.xmlTag == '/XML/Client/CRMDetails/USCitizenInd'}">
            <span class="lfg-radio-section">
              <p class="question-radio_text">{{ data.questionText + (data.required == 'true' ? '' : ' (optional)') }}</p>
              <div class="custom-radio-button">
                <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onRadioChange(data)"/>
                <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onRadioChange(data)">
                <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
              </div>
            </span>
            <lfg-error *ngIf="pageQuestionForm.get(data.fieldId).touched && pageQuestionForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data) }}"></lfg-error>
          </div>

          <!-- Form Element for date  -->
          <div class="form--control lfg-cal" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.DOB">
            <lfg-calendar (focusin)="validateOnFocus(data, 'in')" id="{{data.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(data, 'out')" lfgCheckValidDate [formControl]="pageQuestionForm.get(data.fieldId)" [config]="utilsService.getCalendarConfig(data, dateConfig)" [class.calendar--err]="(pageQuestionForm.get(data.fieldId).touched && pageQuestionForm.get(data.fieldId).invalid)" [required]="data.required == 'true'">
                <lfg-error *ngIf="pageQuestionForm.get(data.fieldId).touched && pageQuestionForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data) }}"></lfg-error>
            </lfg-calendar>
          </div>

          <!-- Form element for Drop Down -->
          <div class="form--control form-dropDown" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT">
            <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(data, ind)" [required]="data.required == 'true'"
            [ngClass]="{'dropdown--err': (pageQuestionForm.get(data.fieldId).touched && pageQuestionForm.get(data.fieldId).invalid)}">
            </lfg-dropdown>
            <lfg-error *ngIf="pageQuestionForm.get(data.fieldId).touched && pageQuestionForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, pageQuestionForm.get(data.fieldId)) }}"></lfg-error>
          </div>

          <!-- Form element for phone number -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.PHONE && data.xmlTag !== '/XML/Client/AgentDetails/CaseManagerPhoneNumber'">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="data.required == 'true'">
              <lfg-error *ngIf="(pageQuestionForm.get(data.fieldId).touched && pageQuestionForm.get(data.fieldId).invalid)" message="{{ getErrorMsg(data) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for phone number -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.PHONE && data.xmlTag === '/XML/Client/AgentDetails/CaseManagerPhoneNumber' && (doYouAttestIndFieldIdValue !== 'i attest')">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="data.required == 'true'">
              <lfg-error *ngIf="(pageQuestionForm.get(data.fieldId).touched && pageQuestionForm.get(data.fieldId).invalid)" message="{{ getErrorMsg(data) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for email  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.EMAIL">
            <lfg-input-wrap>
              <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ data.fieldId }}' autocomplete="off" name="" (blur)="data.xmlTag == '/XML/Client/AgentDetails/CaseManagerEmail' ? getManagerEmail(data, pageQuestionForm) : ''" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="data.required == 'true'">

              <lfg-error *ngIf="pageQuestionForm.get(data.fieldId).touched && pageQuestionForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for alphanumeric  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="data.maxlength" [required]="data.required == 'true'">

              <lfg-error *ngIf="pageQuestionForm.get(data.fieldId).touched && pageQuestionForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <!-- Form element for currency  -->
          <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes == 'ITES_FORMAT_AS_CURRENCY' && data.xmlTag != '/XML/MGeAPP/Riders/LTCABRBaseMonthlyInflation'">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="{{displayTextLabel(data)}}" appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly"
              (blur)="lifeSutabilityZeroCheckKeys.indexOf(data.xmlTag) > -1 ? checkForZeroValue(data) : ''" maxlength='9' [required]="data.required == 'true'" (input)="data.xmlTag == '/XML/MGeAPP/Riders/LTCABRBaseMonthlyRider' ? setTotalLABRCharge() : ''">

              <lfg-error *ngIf="pageQuestionForm.get(data.fieldId).touched && pageQuestionForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, pageQuestionForm.get(data.fieldId)) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

           <!-- Form element for currency  -->
           <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes == 'ITES_FORMAT_AS_CURRENCY' && data.xmlTag == '/XML/MGeAPP/Riders/LTCABRBaseMonthlyInflation' && checkProtectionOption(data);">
            <lfg-input-wrap>
              <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="{{displayTextLabel(data)}}" appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly"
              (blur)="lifeSutabilityZeroCheckKeys.indexOf(data.xmlTag) > -1 ? checkForZeroValue(data) : ''" maxlength='9' [required]="data.required == 'true'" (input)="setTotalLABRCharge()">

              <lfg-error *ngIf="pageQuestionForm.get(data.fieldId).touched && pageQuestionForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, pageQuestionForm.get(data.fieldId)) }}"></lfg-error>
            </lfg-input-wrap>
          </div>

          <div class="question-info" aria-live="assertive" *ngIf="data.xmlTag == '/XML/ApplicationDetail/FaceAmount' && pageQuestionForm.get(data.fieldId).value && (pageQuestionForm.get(data.fieldId).value < 50000 || pageQuestionForm.get(data.fieldId).value > 500000)">
            <lfg-alert-message *ngIf="productService.getMarketName() == 'MGFA'" type="error">The allowable range for the MoneyGuard product is $50,000-$500,000.</lfg-alert-message>
            <lfg-alert-message *ngIf="productService.getMarketName() == 'MGMA' || productService.getMarketName() == 'MGMA22' || productService.getMarketName() == 'MGMA24'" type="error">The allowable range for the MoneyGuard Market Advantage product is $50,000-$500,000.</lfg-alert-message>
          </div>

          <div class="question-info" aria-live="assertive" *ngIf="data.xmlTag == '/XML/ApplicationDetail/VULSuppNetWorth' && zeroCheckFlag">
            <lfg-alert-message type="warning">Please enter a value greater than $0.00.</lfg-alert-message>
          </div>

          <!-- Form element for check box  -->
          <div class="form--control form--control__full-width" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX && data.xmlTag != '/XML/MGeAPP/LTCWorksheet/AgtAcknowledgement'">
            <div class="lfg-checkbox">
            <input id="{{ data.fieldId }}" type="checkbox" value="checked" name="{{ data.fieldId }}"
                formControlName='{{ data.fieldId }}' [required]="data.required == 'true'" (change)="onCheckboxChange(data)">
            <label class="wrap wrap-check" for="{{ data.fieldId }}">{{data.questionText}}</label>
            </div>
          </div>

          <!-- Form element for check box  -->
          <div class="form--control form--control__full-width" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.CHECKBOX && data.xmlTag == '/XML/MGeAPP/LTCWorksheet/AgtAcknowledgement' && declineDisclosure">
            <div class="lfg-checkbox">
            <input id="{{ data.fieldId }}" type="checkbox" value="checked" name="{{ data.fieldId }}"
                formControlName='{{ data.fieldId }}' [required]="data.required == 'true'" (change)="onCheckboxChange(data)">
            <label class="wrap wrap-check" for="{{ data.fieldId }}">{{data.questionText}}</label>
            </div>
          </div>

          <!-- Question Info -->
          <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information' && data.questionText.indexOf(ltcSuitabilityMsges.suitabilityIndMsg) > -1 && suitabilityIndMsg">
            <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
          </div>

          <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information' && data.questionText.indexOf(agentReportMsges.sameAsPrimaryAgentEmail) === -1 && data.questionText.indexOf(ltcSuitabilityMsges.suitabilityIndMsg) === -1">
            <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
          </div>

          <div class="question-info" *ngIf="((data.controlTypeDesc == appConfig.fieldTypes.HDR) && (data.subText == 'Information') && (data.questionText.indexOf(agentReportMsges.sameAsPrimaryAgentEmail) > -1) && caseManagerEmail && primaryAgentEmail === caseManagerEmail)">
            <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
          </div>

          <!-- Nestion question Level 1 -->
          <div *ngIf="(data.hasReflexive == 'true' && pageQuestionForm.get(data.fieldId).value) || (data.hasReflexive == 'true' &&
          data.xmlTag == '/XML/LTCWorksheet/FinancialSituationDescribedInd')" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(data) }">
            <ng-container *ngFor="let primaryChildL1 of data.reflexiveQuestionAL; index as primaryChildL1ix">
              <ng-container *ngIf="nestedQuestionCheckPageQuestion(data, primaryChildL1, pageQuestionForm.get(data.fieldId).value)">

                <!-- Questions headers -->
                <div class="questions-header" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.HDR && (primaryChildL1.subText == null || primaryChildL1.subText == '')">
                  <h2>{{ primaryChildL1.questionText }}</h2>
                </div>

                <div class="questions-header" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL1.questionText !== 'Line' && primaryChildL1.subText == 'Error'">
                  <lfg-alert-message type="error">{{ primaryChildL1.questionText }}</lfg-alert-message>
                </div>

                <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                <div class="questionText" *ngIf="addCorrectPlaceHolder(primaryChildL1, 'display') && primaryChildL1.xmlTag != '/XML/MGeAPP/Riders/LTCEBRBaseMonthlyInflation'">
                  {{ addCorrectPlaceHolder(primaryChildL1, 'question')  }}
                </div>

                <div class="questionText" *ngIf="addCorrectPlaceHolder(primaryChildL1, 'display') && primaryChildL1.xmlTag == '/XML/MGeAPP/Riders/LTCEBRBaseMonthlyInflation' && checkProtectionOption()">
                  {{ addCorrectPlaceHolder(primaryChildL1, 'question')  }}
                </div>
                <!-- USA address -->
                <div class="questions-header" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
                  <h2>{{ primaryChildL1.questionText }}</h2>
                </div>

                <!-- Form element for text  -->
                <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.TEXT && (primaryChildL1.notes !== 'ITES_FORMAT_AS_ZIPCODE' && primaryChildL1.notes !== 'ITES_FORMAT_AS_CURRENCY')" [ngClass]="{'resetError': primaryChildL1.xmlTag == '/XML/Client/AppDetails/DriverLicenseNum' && !pageQuestionForm.get(primaryChildL1.fieldId).errors}">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL1) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" type='text' formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="primaryChildL1.xmlTag == '/XML/Client/AppDetails/DriverLicenseNum' ? getCustomAttribute() : 'text'" [maxlength]="primaryChildL1.maxLength >= 999 && primaryChildL1.xmlTag == '/XML/Client/AgentDetails/AgentInfoCont/PremiumDetails' ? 100 : primaryChildL1.maxLength >= 999 ? 30 : primaryChildL1.maxLength" [required]="primaryChildL1.required == 'true'">
                    <lfg-error *ngIf="pageQuestionForm.get(primaryChildL1.fieldId).touched && pageQuestionForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for zip  -->
                <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc === appConfig.fieldTypes.TEXT && primaryChildL1.notes === 'ITES_FORMAT_AS_ZIPCODE'">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" mask='00000' id="{{primaryChildL1.fieldId}}"
                      placeholder="{{ utilsService.getCorrectContent(primaryChildL1, 'placeHolder') }}" appNoSpecialSymbols
                      customAttr="numbersOnly" type='text' [formControl]='pageQuestionForm.get(primaryChildL1.fieldId)'
                      autocomplete="off" appValidateOnBlur value="" maxlength='5'
                      [ngClass]="{'inp-txt--err': (pageQuestionForm.get(primaryChildL1.fieldId).touched && pageQuestionForm.get(primaryChildL1.fieldId).invalid) }"
                      [required]="primaryChildL1.required === 'true'">

                    <lfg-error
                      *ngIf="pageQuestionForm.get(primaryChildL1.fieldId).touched && pageQuestionForm.get(primaryChildL1.fieldId).invalid"
                      message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for alphanumeric  -->
                <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL1) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="primaryChildL1.maxLength" [required]="primaryChildL1.required == 'true'">

                    <lfg-error *ngIf="pageQuestionForm.get(primaryChildL1.fieldId).touched && pageQuestionForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for phone number -->
                <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.PHONE">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="primaryChildL1.required == 'true'">
                    <lfg-error *ngIf="(pageQuestionForm.get(primaryChildL1.fieldId).touched && pageQuestionForm.get(primaryChildL1.fieldId).invalid)" message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for email  -->
                <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.EMAIL">
                  <lfg-input-wrap>
                    <input lfgInput placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" name="{{ displayTextLabel(primaryChildL1) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="primaryChildL1.required == 'true'">

                    <lfg-error *ngIf="pageQuestionForm.get(primaryChildL1.fieldId).touched && pageQuestionForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <!-- Form element for Drop Down -->
                <div class="form--control form-dropDown" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.SELECT" [ngClass]="{'drop-down-medium': primaryChildL1.questionText.length > 32}">
                  <span class="screen-reader-only" *ngIf="primaryChildL1.xmlTag === '/XML/Client/CRMDetails/StateOfBirth'">{{ primaryChildL1.questionText }}</span>
                  <lfg-dropdown formControlName="{{ primaryChildL1.fieldId }}" id="{{displayIdCreation(primaryChildL1)}}_{{primaryChildL1ix}}" [options]="alldropDownOptions[primaryChildL1.fieldId]" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(primaryChildL1, primaryChildL1ix)" [required]="primaryChildL1.required == 'true'"
                  [ngClass]="{'dropdown--err': (pageQuestionForm.get(primaryChildL1.fieldId).touched && pageQuestionForm.get(primaryChildL1.fieldId).invalid)}">
                  </lfg-dropdown>
                  <lfg-error *ngIf="pageQuestionForm.get(primaryChildL1.fieldId).touched && pageQuestionForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                </div>

                <!-- Form element for radio question -->
                <div class="lfg-customRadio-field" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.RADIO"
                [ngClass]="{'radio--err': (pageQuestionForm.get(primaryChildL1.fieldId).touched && pageQuestionForm.get(primaryChildL1.fieldId).invalid)}">
                  <span class="lfg-radio-section">
                    <p class="question-radio_text">{{ primaryChildL1.questionText + (primaryChildL1.required == 'true' ? '' : ' (optional)') }}</p>
                    <div class="custom-radio-button">
                      <input [attr.aria-label]="primaryChildL1.questionOption[0].description" tabindex="0" id="yes_{{ primaryChildL1.fieldId }}" type="radio" formControlName="{{ primaryChildL1.fieldId }}" name="{{ primaryChildL1.fieldId }}" value="{{ primaryChildL1.questionOption[0].value }}" required="{{primaryChildL1.required}}" (change)="onRadioChange(primaryChildL1)"/>
                      <label for="yes_{{ primaryChildL1.fieldId }}">{{ primaryChildL1.questionOption[0].description }}</label>
                      <input *ngIf="primaryChildL1.questionOption[1]" [attr.aria-label]="primaryChildL1.questionOption[1].description" tabindex="0" id="no_{{ primaryChildL1.fieldId }}" type="radio" formControlName="{{ primaryChildL1.fieldId }}" name="{{ primaryChildL1.fieldId }}" value="{{ primaryChildL1.questionOption[1].value }}" required="{{primaryChildL1.required}}" (change)="onRadioChange(primaryChildL1)">
                      <label *ngIf="primaryChildL1.questionOption[1]" for="no_{{ primaryChildL1.fieldId }}">{{ primaryChildL1.questionOption[1].description }}</label>
                    </div>
                  </span>
                  <lfg-error *ngIf="pageQuestionForm.get(primaryChildL1.fieldId).touched && pageQuestionForm.get(primaryChildL1.fieldId).invalid " message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                </div>

                <lfg-alert-message *ngIf="primaryChildL1.xmlTag === '/XML/MGeAPP/LTCWorksheet/InflationProtectionInd' && inflationProtectionMsg" type="error">
                  The 'Will you buy inflation protection?' answer does not match the 'Optional Inflation Protection Option' selection on the <a (click)="redirectToPolicyInfo()">Policy Information</a> Screen. Please correct your selections before moving forward.
                </lfg-alert-message>

                <!-- Form element for currency  -->
                <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.TEXT && primaryChildL1.notes == 'ITES_FORMAT_AS_CURRENCY' && primaryChildL1.xmlTag != '/XML/MGeAPP/Riders/LTCEBRBaseMonthlyInflation'">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" type='text' formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" name="{{displayTextLabel(primaryChildL1)}}" appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" maxlength='9' [required]="primaryChildL1.required == 'true'" (input)="primaryChildL1.xmlTag == '/XML/MGeAPP/Riders/LTCEBRBaseMonthlyRider' ? setTotalLEBRCharge() : ''">

                    <lfg-error *ngIf="pageQuestionForm.get(primaryChildL1.fieldId).touched && pageQuestionForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1, pageQuestionForm.get(primaryChildL1.fieldId)) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.TEXT && primaryChildL1.notes == 'ITES_FORMAT_AS_CURRENCY' && primaryChildL1.xmlTag == '/XML/MGeAPP/Riders/LTCEBRBaseMonthlyInflation' && checkProtectionOption(primaryChildL1)">
                  <lfg-input-wrap>
                    <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" type='text' formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" name="{{displayTextLabel(primaryChildL1)}}" appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" maxlength='9' [required]="primaryChildL1.required == 'true'" (input)="setTotalLEBRCharge()">

                    <lfg-error *ngIf="pageQuestionForm.get(primaryChildL1.fieldId).touched && pageQuestionForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1, pageQuestionForm.get(primaryChildL1.fieldId)) }}"></lfg-error>
                  </lfg-input-wrap>
                </div>

                <lfg-alert-message *ngIf="primaryChildL1.xmlTag === '/XML/Client/CRMDetails/GreenCardInd' && noGreenCardholder" type="error">{{ greenCardErrorMessage }}</lfg-alert-message>


                <!-- Nestion question Level 2 -->
                <div *ngIf="primaryChildL1.hasReflexive == 'true' && pageQuestionForm.get(primaryChildL1.fieldId).value as parentAnswer" [ngClass]="{'nested-questions no-border_bottom': checkIfQuestionDisplayed(primaryChildL1), 'no-border-top': primaryChildL1.xmlTag == '/XML/LTCWorksheet/SourceOfPremium' || primaryChildL1.xmlTag == '/XML/MGeAPP/LTCWorksheet/InflationProtectionInd'}">
                  <ng-container *ngFor="let primaryChildL2 of primaryChildL1.reflexiveQuestionAL; index as primaryChildL2ix">
                    <ng-container *ngIf="nestedQuestionCheckPageQuestion(primaryChildL1, primaryChildL2, parentAnswer)">

                      <!-- Questions headers -->
                      <div class="questions-header" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.HDR && (primaryChildL2.subText == null || primaryChildL2.subText == '')">
                        <h2>{{ primaryChildL2.questionText }}</h2>
                      </div>

                      <div class="questions-header" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL2.questionText !== 'Line' && primaryChildL2.subText == 'Error'">
                        <lfg-alert-message type="error">{{ primaryChildL2.questionText }}</lfg-alert-message>
                        </div>

                      <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                      <div class="questionText" *ngIf="addCorrectPlaceHolder(primaryChildL2, 'display')">
                        {{ addCorrectPlaceHolder(primaryChildL2, 'question')  }}
                      </div>

                      <!-- USA address -->
                      <div class="questions-header" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
                        <h2>{{ primaryChildL2.questionText }}</h2>
                      </div>

                      <!-- Form element for text  -->
                      <div class="form--control" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.TEXT && (primaryChildL2.notes !== 'ITES_FORMAT_AS_ZIPCODE' && primaryChildL2.notes !== 'ITES_FORMAT_AS_CURRENCY')" [ngClass]="{'resetError': primaryChildL2.xmlTag == '/XML/Client/AppDetails/DriverLicenseNum' && !pageQuestionForm.get(primaryChildL2.fieldId).errors}">
                        <lfg-input-wrap>
                          <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL2) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL2, 'placeHolder') }}" type='text' formControlName='{{ primaryChildL2.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="'text'" [maxlength]="primaryChildL2.maxLength >= 999 ? 30 : primaryChildL2.maxLength" [required]="primaryChildL2.required == 'true'">
                          <lfg-error *ngIf="pageQuestionForm.get(primaryChildL2.fieldId).touched && pageQuestionForm.get(primaryChildL2.fieldId).invalid" message="{{ getErrorMsg(primaryChildL2) }}"></lfg-error>
                        </lfg-input-wrap>
                      </div>

                      <!-- Form element for currency  -->
                      <div class="form--control" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.TEXT && primaryChildL2.notes == 'ITES_FORMAT_AS_CURRENCY'">
                        <lfg-input-wrap>
                          <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(primaryChildL2, 'placeHolder') }}" type='text' formControlName='{{ primaryChildL2.fieldId }}' autocomplete="off" name="{{displayTextLabel(primaryChildL2)}}" appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" maxlength='9' [required]="primaryChildL2.required == 'true'">

                          <lfg-error *ngIf="pageQuestionForm.get(primaryChildL2.fieldId).touched && pageQuestionForm.get(primaryChildL2.fieldId).invalid" message="{{ getErrorMsg(primaryChildL2, pageQuestionForm.get(primaryChildL2.fieldId)) }}"></lfg-error>
                        </lfg-input-wrap>
                      </div>

                      <!-- Form element for zip  -->
                      <div class="form--control" *ngIf="primaryChildL2.controlTypeDesc === appConfig.fieldTypes.TEXT && primaryChildL2.notes === 'ITES_FORMAT_AS_ZIPCODE'">
                        <lfg-input-wrap>
                          <input lfgInput aria-live="off" mask='00000' id="{{primaryChildL2.fieldId}}"
                            placeholder="{{ utilsService.getCorrectContent(primaryChildL2, 'placeHolder') }}" appNoSpecialSymbols
                            customAttr="numbersOnly" type='text' [formControl]='pageQuestionForm.get(primaryChildL2.fieldId)'
                            autocomplete="off" appValidateOnBlur value="" maxlength='5'
                            [ngClass]="{'inp-txt--err': (pageQuestionForm.get(primaryChildL2.fieldId).touched && pageQuestionForm.get(primaryChildL2.fieldId).invalid) }"
                            [required]="primaryChildL2.required === 'true'">

                          <lfg-error
                            *ngIf="pageQuestionForm.get(primaryChildL2.fieldId).touched && pageQuestionForm.get(primaryChildL2.fieldId).invalid"
                            message="{{ getErrorMsg(primaryChildL2) }}"></lfg-error>
                        </lfg-input-wrap>
                      </div>

                      <!-- Form element for alphanumeric  -->
                      <div class="form--control" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                        <lfg-input-wrap>
                          <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL2) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL2, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ primaryChildL2.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="primaryChildL2.maxLength" [required]="primaryChildL2.required == 'true'">

                          <lfg-error *ngIf="pageQuestionForm.get(primaryChildL2.fieldId).touched && pageQuestionForm.get(primaryChildL2.fieldId).invalid" message="{{ getErrorMsg(primaryChildL2) }}"></lfg-error>
                        </lfg-input-wrap>
                      </div>

                      <!-- Form element for phone number -->
                      <div class="form--control" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.PHONE">
                        <lfg-input-wrap>
                          <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(primaryChildL2, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ primaryChildL2.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="primaryChildL2.required == 'true'">
                          <lfg-error *ngIf="(pageQuestionForm.get(primaryChildL2.fieldId).touched && pageQuestionForm.get(primaryChildL2.fieldId).invalid)" message="{{ getErrorMsg(primaryChildL2) }}"></lfg-error>
                        </lfg-input-wrap>
                      </div>

                      <!-- Form element for email  -->
                      <div class="form--control" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.EMAIL">
                        <lfg-input-wrap>
                          <input lfgInput placeholder="{{ addCorrectPlaceHolder(primaryChildL2, 'placeHolder') }}" name="{{ displayTextLabel(primaryChildL2) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ primaryChildL2.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="primaryChildL2.required == 'true'">

                          <lfg-error *ngIf="pageQuestionForm.get(primaryChildL2.fieldId).touched && pageQuestionForm.get(primaryChildL2.fieldId).invalid" message="{{ getErrorMsg(primaryChildL2) }}"></lfg-error>
                        </lfg-input-wrap>
                      </div>

                      <!-- Form element for Drop Down -->
                      <div class="form--control form-dropDown" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.SELECT" [ngClass]="{'drop-down-medium': primaryChildL2.questionText.length > 32}">
                        <span class="screen-reader-only" *ngIf="primaryChildL2.xmlTag === '/XML/Client/CRMDetails/StateOfBirth'">{{ primaryChildL2.questionText }}</span>
                        <lfg-dropdown formControlName="{{ primaryChildL2.fieldId }}" id="{{displayIdCreation(primaryChildL2)}}_{{primaryChildL2ix}}" [options]="alldropDownOptions[primaryChildL2.fieldId]" placeholder="{{ addCorrectPlaceHolder(primaryChildL2, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(primaryChildL2, primaryChildL2ix)" [required]="primaryChildL2.required == 'true'"
                        [ngClass]="{'dropdown--err': (pageQuestionForm.get(primaryChildL2.fieldId).touched && pageQuestionForm.get(primaryChildL2.fieldId).invalid)}">
                        </lfg-dropdown>
                        <lfg-error *ngIf="pageQuestionForm.get(primaryChildL2.fieldId).touched && pageQuestionForm.get(primaryChildL2.fieldId).invalid" message="{{ getErrorMsg(primaryChildL2) }}"></lfg-error>
                      </div>

                      <!-- Form element for radio question -->
                      <div class="lfg-customRadio-field" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.RADIO"
                      [ngClass]="{'radio--err': (pageQuestionForm.get(primaryChildL2.fieldId).touched && pageQuestionForm.get(primaryChildL2.fieldId).invalid)}">
                        <span class="lfg-radio-section">
                          <p class="question-radio_text">{{ primaryChildL2.questionText + (primaryChildL2.required == 'true' ? '' : ' (optional)') }}</p>
                          <div class="custom-radio-button">
                            <input [attr.aria-label]="primaryChildL2.questionOption[0].description" tabindex="0" id="yes_{{ primaryChildL2.fieldId }}" type="radio" formControlName="{{ primaryChildL2.fieldId }}" name="{{ primaryChildL2.fieldId }}" value="{{ primaryChildL2.questionOption[0].value }}" required="{{primaryChildL2.required}}" (change)="onRadioChange(primaryChildL2)"/>
                            <label for="yes_{{ primaryChildL2.fieldId }}">{{ primaryChildL2.questionOption[0].description }}</label>
                            <input [attr.aria-label]="primaryChildL2.questionOption[1].description" tabindex="0" id="no_{{ primaryChildL2.fieldId }}" type="radio" formControlName="{{ primaryChildL2.fieldId }}" name="{{ primaryChildL2.fieldId }}" value="{{ primaryChildL2.questionOption[1].value }}" required="{{primaryChildL2.required}}" (change)="onRadioChange(primaryChildL2)">
                            <label for="no_{{ primaryChildL2.fieldId }}">{{ primaryChildL2.questionOption[1].description }}</label>
                          </div>
                        </span>
                        <lfg-error *ngIf="pageQuestionForm.get(primaryChildL2.fieldId).touched && pageQuestionForm.get(primaryChildL2.fieldId).invalid " message="{{ getErrorMsg(primaryChildL2) }}"></lfg-error>
                      </div>

                      <lfg-alert-message *ngIf="primaryChildL2.xmlTag === '/XML/Client/CRMDetails/GreenCardInd' && noGreenCardholder" type="error">{{ greenCardErrorMessage }}</lfg-alert-message>

                      <lfg-alert-message *ngIf="primaryChildL2.xmlTag === TIAConstants.methodOfPayment && pageQuestionForm.get(primaryChildL2.fieldId)?.value?.value == 'EFT' && isEFT == 'true'" type="info">{{ eftExistsMsg }}</lfg-alert-message>

                      <!-- Nestion question Level 3 -->
                      <div *ngIf="primaryChildL2.hasReflexive == 'true' && pageQuestionForm.get(primaryChildL2.fieldId).value as parentAnswer" [ngClass]="{'nested-questions no-border_bottom': checkIfQuestionDisplayed(primaryChildL2), 'no-border-top': primaryChildL2.xmlTag == '/XML/LTCWorksheet/FutureSourceOfPremium'}">
                        <ng-container *ngFor="let primaryChildL3 of primaryChildL2.reflexiveQuestionAL | dataFilter: null: null: questionsData?.page_group_name: null: null: {'isEFT': isEFT}; index as primaryChildL3ix">
                          <ng-container *ngIf="nestedQuestionCheckPageQuestion(primaryChildL2, primaryChildL3, parentAnswer)">

                            <!-- element to switch controls to new line -->
                            <div class="full-width-ele" *ngIf="pushFieldToNewLine.indexOf((primaryChildL3.questionText).toLowerCase()) !== -1 && primaryChildL3.questionText !== 'Who is the accountholder?' ">
                            </div>

                            <!-- Questions headers -->
                            <div class="questions-header" *ngIf="primaryChildL3.controlTypeDesc == appConfig.fieldTypes.HDR">
                            <h2>{{ primaryChildL3.questionText }}</h2>
                            </div>

                            <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                            <div class="questionText" *ngIf="addCorrectPlaceHolder(primaryChildL3, 'display')">
                              {{ addCorrectPlaceHolder(primaryChildL3, 'question')  }}
                            </div>

                            <!-- USA address -->
                            <div class="questions-header" *ngIf="primaryChildL3.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
                              <h2>{{ primaryChildL3.questionText }}</h2>
                            </div>

                            <!-- Form element for text  -->
                            <div class="form--control" *ngIf="primaryChildL3.controlTypeDesc == appConfig.fieldTypes.TEXT && (primaryChildL3.notes !== 'ITES_FORMAT_AS_ZIPCODE')" [ngClass]="{'resetError': primaryChildL3.xmlTag == '/XML/Client/AppDetails/DriverLicenseNum' && !pageQuestionForm.get(primaryChildL3.fieldId).errors}">
                              <lfg-input-wrap>
                                <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL3) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL3, 'placeHolder') }}" type='text' formControlName='{{ primaryChildL3.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="primaryChildL3.xmlTag == TIAConstants.PACBankRoutingMICRNum || primaryChildL3.xmlTag == TIAConstants.PACAccountNumber ? appConfig.customAttr['Numeric'] : 'text'" [maxlength]="primaryChildL3.maxLength >= 999 ? 30 : primaryChildL3.maxLength"  minlength="{{ primaryChildL3.questionText.toLowerCase().indexOf('routing number') !== -1 ? 9 : 0 }}" [required]="primaryChildL3.required == 'true'">
                                <lfg-error *ngIf="pageQuestionForm.get(primaryChildL3.fieldId).touched && pageQuestionForm.get(primaryChildL3.fieldId).invalid" message="{{ getErrorMsg(primaryChildL3) }}"></lfg-error>
                              </lfg-input-wrap>
                            </div>

                            <!-- Form element for zip  -->
                            <div class="form--control" *ngIf="primaryChildL3.controlTypeDesc === appConfig.fieldTypes.TEXT && primaryChildL3.notes === 'ITES_FORMAT_AS_ZIPCODE'">
                              <lfg-input-wrap>
                                <input lfgInput aria-live="off" mask='00000' id="{{primaryChildL3.fieldId}}"
                                  placeholder="{{ utilsService.getCorrectContent(primaryChildL3, 'placeHolder') }}" appNoSpecialSymbols
                                  type='text' [formControl]='pageQuestionForm.get(primaryChildL3.fieldId)'
                                  autocomplete="off" appValidateOnBlur value="" maxlength='5'
                                  [ngClass]="{'inp-txt--err': (pageQuestionForm.get(primaryChildL3.fieldId).touched && pageQuestionForm.get(primaryChildL3.fieldId).invalid) }"
                                  [required]="primaryChildL3.required === 'true'">

                                <lfg-error
                                  *ngIf="pageQuestionForm.get(primaryChildL3.fieldId).touched && pageQuestionForm.get(primaryChildL3.fieldId).invalid"
                                  message="{{ getErrorMsg(primaryChildL3) }}"></lfg-error>
                              </lfg-input-wrap>
                            </div>

                            <!-- Form element for alphanumeric  -->
                            <div class="form--control" *ngIf="primaryChildL3.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                              <lfg-input-wrap>
                                <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL3) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL3, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ primaryChildL3.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="primaryChildL3.maxLength" [required]="primaryChildL3.required == 'true'">

                                <lfg-error *ngIf="pageQuestionForm.get(primaryChildL3.fieldId).touched && pageQuestionForm.get(primaryChildL3.fieldId).invalid" message="{{ getErrorMsg(primaryChildL3) }}"></lfg-error>
                              </lfg-input-wrap>
                            </div>

                            <!-- Form element for phone number -->
                            <div class="form--control" *ngIf="primaryChildL3.controlTypeDesc == appConfig.fieldTypes.PHONE">
                              <lfg-input-wrap>
                                <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(primaryChildL3, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ primaryChildL3.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="primaryChildL3.required == 'true'">
                                <lfg-error *ngIf="(pageQuestionForm.get(primaryChildL3.fieldId).touched && pageQuestionForm.get(primaryChildL3.fieldId).invalid)" message="{{ getErrorMsg(primaryChildL3) }}"></lfg-error>
                              </lfg-input-wrap>
                            </div>

                            <!-- Form element for email  -->
                            <div class="form--control" *ngIf="primaryChildL3.controlTypeDesc == appConfig.fieldTypes.EMAIL">
                              <lfg-input-wrap>
                                <input lfgInput placeholder="{{ addCorrectPlaceHolder(primaryChildL3, 'placeHolder') }}" name="{{ displayTextLabel(primaryChildL3) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ primaryChildL3.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="primaryChildL3.required == 'true'">

                                <lfg-error *ngIf="pageQuestionForm.get(primaryChildL3.fieldId).touched && pageQuestionForm.get(primaryChildL3.fieldId).invalid" message="{{ getErrorMsg(primaryChildL3) }}"></lfg-error>
                              </lfg-input-wrap>
                            </div>

                            <!-- Form element for Drop Down -->
                            <div class="form--control form-dropDown" *ngIf="primaryChildL3.controlTypeDesc == appConfig.fieldTypes.SELECT" [ngClass]="{'drop-down-medium': primaryChildL3.questionText.length > 32}">
                              <span class="screen-reader-only" *ngIf="primaryChildL3.xmlTag === '/XML/Client/CRMDetails/StateOfBirth'">{{ primaryChildL3.questionText }}</span>
                              <lfg-dropdown formControlName="{{ primaryChildL3.fieldId }}" id="{{displayIdCreation(primaryChildL3)}}_{{primaryChildL3ix}}" [options]="alldropDownOptions[primaryChildL3.fieldId]" placeholder="{{ addCorrectPlaceHolder(primaryChildL3, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(primaryChildL3, primaryChildL3ix)" [required]="primaryChildL3.required == 'true'"
                              [ngClass]="{'dropdown--err': (pageQuestionForm.get(primaryChildL3.fieldId).touched && pageQuestionForm.get(primaryChildL3.fieldId).invalid)}">
                              </lfg-dropdown>
                              <lfg-error *ngIf="pageQuestionForm.get(primaryChildL3.fieldId).touched && pageQuestionForm.get(primaryChildL3.fieldId).invalid" message="{{ getErrorMsg(primaryChildL3) }}"></lfg-error>
                            </div>

                            <!-- Form element for radio question -->
                            <div class="lfg-customRadio-field" *ngIf="primaryChildL3.controlTypeDesc == appConfig.fieldTypes.RADIO"
                            [ngClass]="{'radio--err': (pageQuestionForm.get(primaryChildL3.fieldId).touched && pageQuestionForm.get(primaryChildL3.fieldId).invalid)}">
                              <span class="lfg-radio-section">
                                <p class="question-radio_text">{{ primaryChildL3.questionText + (primaryChildL3.required == 'true' ? '' : ' (optional)') }}</p>
                                <div class="custom-radio-button">
                                  <input [attr.aria-label]="primaryChildL3.questionOption[0].description" tabindex="0" id="yes_{{ primaryChildL3.fieldId }}" type="radio" formControlName="{{ primaryChildL3.fieldId }}" name="{{ primaryChildL3.fieldId }}" value="{{ primaryChildL3.questionOption[0].value }}" required="{{primaryChildL3.required}}" (change)="onRadioChange(primaryChildL3)"/>
                                  <label for="yes_{{ primaryChildL3.fieldId }}">{{ primaryChildL3.questionOption[0].description }}</label>
                                  <input [attr.aria-label]="primaryChildL3.questionOption[1].description" tabindex="0" id="no_{{ primaryChildL3.fieldId }}" type="radio" formControlName="{{ primaryChildL3.fieldId }}" name="{{ primaryChildL3.fieldId }}" value="{{ primaryChildL3.questionOption[1].value }}" required="{{primaryChildL3.required}}" (change)="onRadioChange(primaryChildL3)">
                                  <label for="no_{{ primaryChildL3.fieldId }}">{{ primaryChildL3.questionOption[1].description }}</label>
                                </div>
                              </span>
                              <lfg-error *ngIf="pageQuestionForm.get(primaryChildL3.fieldId).touched && pageQuestionForm.get(primaryChildL3.fieldId).invalid " message="{{ getErrorMsg(primaryChildL3) }}"></lfg-error>
                            </div>

                            <lfg-alert-message *ngIf="primaryChildL3.xmlTag === '/XML/Client/CRMDetails/GreenCardInd' && noGreenCardholder" type="error">{{ greenCardErrorMessage }}</lfg-alert-message>

                            <!-- element to switch controls to new line -->
                            <div class="full-width-ele" *ngIf="primaryChildL3.questionText === 'Who is the accountholder?' &&pushFieldToNewLine.indexOf((primaryChildL3.questionText).toLowerCase()) !== -1 ">
                            </div>

                            <!-- Nestion question Level 4 -->
                            <div *ngIf="primaryChildL3.hasReflexive == 'true' && pageQuestionForm.get(primaryChildL3.fieldId).value as parentAnswer" [ngClass]="{'nested-questions no-border_bottom': checkIfQuestionDisplayed(primaryChildL3) }">
                              <ng-container *ngFor="let primaryChildL4 of primaryChildL3.reflexiveQuestionAL; index as primaryChildL3ix">
                                <ng-container *ngIf="nestedQuestionCheckPageQuestion(primaryChildL3, primaryChildL4, parentAnswer)">

                                  <!-- element to switch controls to new line -->
                                  <div class="full-width-ele" *ngIf="pushFieldToNewLine.indexOf((primaryChildL4.questionText).toLowerCase()) !== -1">
                                  </div>

                                  <!-- Questions headers -->
                                  <div class="questions-header" *ngIf="primaryChildL4.controlTypeDesc == appConfig.fieldTypes.HDR">
                                  <h2>{{ primaryChildL4.questionText }}</h2>
                                  </div>

                                  <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                                  <div class="questionText" *ngIf="addCorrectPlaceHolder(primaryChildL4, 'display')">
                                    {{ addCorrectPlaceHolder(primaryChildL4, 'question')  }}
                                  </div>

                                  <!-- USA address -->
                                  <div class="questions-header" *ngIf="primaryChildL4.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
                                    <h2>{{ primaryChildL4.questionText }}</h2>
                                  </div>

                                  <!-- Form element for text  -->
                                  <div class="form--control" *ngIf="primaryChildL4.controlTypeDesc == appConfig.fieldTypes.TEXT && (primaryChildL4.notes !== 'ITES_FORMAT_AS_ZIPCODE')" [ngClass]="{'resetError': primaryChildL4.xmlTag == '/XML/Client/AppDetails/DriverLicenseNum' && !pageQuestionForm.get(primaryChildL4.fieldId).errors}">
                                    <lfg-input-wrap>
                                      <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL4) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL4, 'placeHolder') }}" type='text' formControlName='{{ primaryChildL4.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="'text'" [maxlength]="primaryChildL4.maxLength >= 999 ? 30 : primaryChildL4.maxLength" [required]="primaryChildL4.required == 'true'">
                                      <lfg-error *ngIf="pageQuestionForm.get(primaryChildL4.fieldId).touched && pageQuestionForm.get(primaryChildL4.fieldId).invalid" message="{{ getErrorMsg(primaryChildL4) }}"></lfg-error>
                                    </lfg-input-wrap>
                                  </div>

                                  <!-- Form element for zip  -->
                                  <div class="form--control" *ngIf="primaryChildL4.controlTypeDesc === appConfig.fieldTypes.TEXT && primaryChildL4.notes === 'ITES_FORMAT_AS_ZIPCODE'">
                                    <lfg-input-wrap>
                                      <input lfgInput aria-live="off" mask='00000' id="{{primaryChildL4.fieldId}}"
                                        placeholder="{{ utilsService.getCorrectContent(primaryChildL4, 'placeHolder') }}" appNoSpecialSymbols
                                        type='text' [formControl]='pageQuestionForm.get(primaryChildL4.fieldId)'
                                        autocomplete="off" appValidateOnBlur value="" maxlength='5'
                                        [ngClass]="{'inp-txt--err': (pageQuestionForm.get(primaryChildL4.fieldId).touched && pageQuestionForm.get(primaryChildL4.fieldId).invalid) }"
                                        [required]="primaryChildL4.required === 'true'">

                                      <lfg-error
                                        *ngIf="pageQuestionForm.get(primaryChildL4.fieldId).touched && pageQuestionForm.get(primaryChildL4.fieldId).invalid"
                                        message="{{ getErrorMsg(primaryChildL4) }}"></lfg-error>
                                    </lfg-input-wrap>
                                  </div>

                                  <!-- Form element for alphanumeric  -->
                                  <div class="form--control" *ngIf="primaryChildL4.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                                    <lfg-input-wrap>
                                      <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL4) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL4, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ primaryChildL4.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="primaryChildL4.maxLength" [required]="primaryChildL4.required == 'true'">

                                      <lfg-error *ngIf="pageQuestionForm.get(primaryChildL4.fieldId).touched && pageQuestionForm.get(primaryChildL4.fieldId).invalid" message="{{ getErrorMsg(primaryChildL4) }}"></lfg-error>
                                    </lfg-input-wrap>
                                  </div>

                                  <!-- Form element for phone number -->
                                  <div class="form--control" *ngIf="primaryChildL4.controlTypeDesc == appConfig.fieldTypes.PHONE">
                                    <lfg-input-wrap>
                                      <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(primaryChildL4, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ primaryChildL4.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="primaryChildL4.required == 'true'">
                                      <lfg-error *ngIf="(pageQuestionForm.get(primaryChildL4.fieldId).touched && pageQuestionForm.get(primaryChildL4.fieldId).invalid)" message="{{ getErrorMsg(primaryChildL4) }}"></lfg-error>
                                    </lfg-input-wrap>
                                  </div>

                                  <!-- Form element for email  -->
                                  <div class="form--control" *ngIf="primaryChildL4.controlTypeDesc == appConfig.fieldTypes.EMAIL">
                                    <lfg-input-wrap>
                                      <input lfgInput placeholder="{{ addCorrectPlaceHolder(primaryChildL4, 'placeHolder') }}" name="{{ displayTextLabel(primaryChildL4) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ primaryChildL4.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="primaryChildL4.required == 'true'">

                                      <lfg-error *ngIf="pageQuestionForm.get(primaryChildL4.fieldId).touched && pageQuestionForm.get(primaryChildL4.fieldId).invalid" message="{{ getErrorMsg(primaryChildL4) }}"></lfg-error>
                                    </lfg-input-wrap>
                                  </div>

                                  <!-- Form element for Drop Down -->
                                  <div class="form--control form-dropDown" *ngIf="primaryChildL4.controlTypeDesc == appConfig.fieldTypes.SELECT" [ngClass]="{'drop-down-medium': primaryChildL4.questionText.length > 32}">
                                    <span class="screen-reader-only" *ngIf="primaryChildL4.xmlTag === '/XML/Client/CRMDetails/StateOfBirth'">{{ primaryChildL4.questionText }}</span>
                                    <lfg-dropdown formControlName="{{ primaryChildL4.fieldId }}" id="{{displayIdCreation(primaryChildL4)}}_{{primaryChildL4ix}}" [options]="alldropDownOptions[primaryChildL4.fieldId]" placeholder="{{ addCorrectPlaceHolder(primaryChildL4, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(primaryChildL4, primaryChildL4ix)" [required]="primaryChildL4.required == 'true'"
                                    [ngClass]="{'dropdown--err': (pageQuestionForm.get(primaryChildL4.fieldId).touched && pageQuestionForm.get(primaryChildL4.fieldId).invalid)}">
                                    </lfg-dropdown>
                                    <lfg-error *ngIf="pageQuestionForm.get(primaryChildL4.fieldId).touched && pageQuestionForm.get(primaryChildL4.fieldId).invalid" message="{{ getErrorMsg(primaryChildL4) }}"></lfg-error>
                                  </div>

                                  <!-- Form element for radio question -->
                                  <div class="lfg-customRadio-field" *ngIf="primaryChildL4.controlTypeDesc == appConfig.fieldTypes.RADIO"
                                  [ngClass]="{'radio--err': (pageQuestionForm.get(primaryChildL4.fieldId).touched && pageQuestionForm.get(primaryChildL4.fieldId).invalid)}">
                                    <span class="lfg-radio-section">
                                      <p class="question-radio_text">{{ primaryChildL4.questionText + (primaryChildL4.required == 'true' ? '' : ' (optional)') }}</p>
                                      <div class="custom-radio-button">
                                        <input [attr.aria-label]="primaryChildL4.questionOption[0].description" tabindex="0" id="yes_{{ primaryChildL4.fieldId }}" type="radio" formControlName="{{ primaryChildL4.fieldId }}" name="{{ primaryChildL4.fieldId }}" value="{{ primaryChildL4.questionOption[0].value }}" required="{{primaryChildL4.required}}" (change)="onRadioChange(primaryChildL4)"/>
                                        <label for="yes_{{ primaryChildL4.fieldId }}">{{ primaryChildL4.questionOption[0].description }}</label>
                                        <input [attr.aria-label]="primaryChildL4.questionOption[1].description" tabindex="0" id="no_{{ primaryChildL4.fieldId }}" type="radio" formControlName="{{ primaryChildL4.fieldId }}" name="{{ primaryChildL4.fieldId }}" value="{{ primaryChildL4.questionOption[1].value }}" required="{{primaryChildL4.required}}" (change)="onRadioChange(primaryChildL4)">
                                        <label for="no_{{ primaryChildL4.fieldId }}">{{ primaryChildL4.questionOption[1].description }}</label>
                                      </div>
                                    </span>
                                    <lfg-error *ngIf="pageQuestionForm.get(primaryChildL4.fieldId).touched && pageQuestionForm.get(primaryChildL4.fieldId).invalid " message="{{ getErrorMsg(primaryChildL4) }}"></lfg-error>
                                  </div>


                                </ng-container>
                              </ng-container>
                            </div>


                          </ng-container>
                        </ng-container>
                      </div>

                    </ng-container>
                  </ng-container>
                </div>

              </ng-container>
            </ng-container>
          </div>
          <div class="section-divider" *ngIf="data.xmlTag != '/XML/Client/CRMDetails/HomePhone' && data.xmlTag != '/XML/Client/CRMDetails/MobilePhone' && data.questionText != 'Do you have a partner as defined on page 4 item 8 of this application?'" [ngClass]="{'required': data.xmlTag == '/XML/Client/AppDetails/Email' || data.xmlTag === '/XML/Client/CRMDetails/PrimaryPhoneType'}"></div>
        </ng-container>
      </form>
    </section>
  </div>
</section>
