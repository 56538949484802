<section class="billing-question-page page-content-container">

    <div class="questions--container">
      <h1>
        Premium & Billing Information
      </h1>
      <div class="loader-container" *ngIf="showLoader">
        <lfg-loader></lfg-loader>
      </div>
      <lfg-alert-message *ngIf="isTabVisited && !formValid" type="error">{{errorMessage}}</lfg-alert-message>
      <section>
        <form class="form-group" [formGroup]="billingQuestionForm" id="billingQuestionForm" method="post" *ngIf="billingQuestionData && billingQuestionData.length > 0">
          <ng-container class="form-control-parent" *ngFor="let data of billingQuestionData | dataFilter: null: null: questionsData?.page_group_name: billingQuestionForm: null: {'premiumMode': premiumMode}; let ind = index;" >

            <div class="section-divider required" *ngIf="data.xmlTag == billingKeys.mecInd || data.xmlTag == billingKeys.premiumChangeInd"></div>

            <!-- element to switch controls to new line -->
            <div class="full-width-ele" *ngIf="pushFieldToNewLine.indexOf((data.questionText).toLowerCase()) !== -1">
            </div>

            <!-- Questions headers -->
            <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && premiumModeOverrideCheck(data)">
              <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
            </div>

            <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && (checkMaxPremiumAmountQues(data) || checLumpsumAmount(data) || checkAllowableYearsPayble(data))">
              <lfg-alert-message type="error" *ngIf="!checkAllowableYearsPayble(data)">{{ data.questionText }}</lfg-alert-message>
              <lfg-alert-message type="info" *ngIf="checkAllowableYearsPayble(data)">{{ data.questionText }}</lfg-alert-message>
            </div>

            <!-- Questions headers -->
            <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText === 'Payor Information'">
              <h2>{{ data.questionText }}</h2>
            </div>

            <div class="section-divider" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.questionText == 'Line'">
            </div>

            <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
            <div class="questionText" *ngIf="addCorrectPlaceHolder(data, 'display')">
              <span [innerHTML]="addCorrectPlaceHolder(data, 'question')"></span>
            </div>

            <!-- USA address -->
            <div class="questions-header" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
              <h2>{{ data.questionText }}</h2>
            </div>

            <!-- Form element for text  -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && (data.notes !== 'ITES_FORMAT_AS_ZIPCODE') && data.notes !== 'ITES_FORMAT_AS_CURRENCY'" [ngClass]="{'resetError': !billingQuestionForm.get(data.fieldId).errors}">
              <lfg-input-wrap>
                <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' customAttr="text" formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols [maxlength]="data.maxLength >= 999 ? 30 : data.maxLength" [required]="data.required == 'true'">

                <lfg-error *ngIf="billingQuestionForm.get(data.fieldId).touched && billingQuestionForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for currency  -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.TEXT && data.notes == 'ITES_FORMAT_AS_CURRENCY' && (data.xmlTag == billingKeys.paymentLumpSumAmount ? premiumMode !== 'Single Premium' : true)">
              <lfg-input-wrap>
                <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="{{displayTextLabel(data)}}" appCurrencyFormat (input)="data.xmlTag == billingKeys.SelectedPremium ? checkMaxPremiumAmount(data.fieldId) : undefined" allowDecimals="false" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" maxlength='9' [required]="data.required == 'true'">

                <lfg-error *ngIf="billingQuestionForm.get(data.fieldId)?.value === '' && billingQuestionForm.get(data.fieldId).touched && billingQuestionForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, billingQuestionForm.get(data.fieldId)) }}"></lfg-error>

                <lfg-error *ngIf="billingQuestionForm.get(data.fieldId)?.value !== '' && billingQuestionForm.get(data.fieldId).errors && billingQuestionForm.get(data.fieldId).errors.MaxPremiumAmountError" message="The Maximum allowable amount for the Premium is $500,000."></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for zip  -->
            <div class="form--control" *ngIf="data.controlTypeDesc === appConfig.fieldTypes.TEXT && data.notes === 'ITES_FORMAT_AS_ZIPCODE'">
              <lfg-input-wrap>
                <input lfgInput aria-live="off" mask='00000' id="{{data.fieldId}}"
                  placeholder="{{ utilsService.getCorrectContent(data, 'placeHolder') }}" appNoSpecialSymbols
                  type='text' [formControl]='billingQuestionForm.get(data.fieldId)'
                  autocomplete="off" appValidateOnBlur value="" maxlength='5'
                  [ngClass]="{'inp-txt--err': (billingQuestionForm.get(data.fieldId).touched && billingQuestionForm.get(data.fieldId).invalid) }"
                  [required]="data.required === 'true'">

                <lfg-error
                  *ngIf="billingQuestionForm.get(data.fieldId).touched && billingQuestionForm.get(data.fieldId).invalid"
                  message="{{ getErrorMsg(data) }}"></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for SSN -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SSN">
              <lfg-input-wrap>
                <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" mask='AAA-AA-0000' [formControl]='billingQuestionForm.get(data.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur maxlength='11' [required]="data.required == 'true'">

                <lfg-error *ngIf="(billingQuestionForm.get(data.fieldId).touched && billingQuestionForm.get(data.fieldId).invalid) && ( billingQuestionForm.get(data.fieldId).errors == null || (billingQuestionForm.get(data.fieldId).errors != null && !billingQuestionForm.get(data.fieldId).errors.notUnique))" message="Please enter a valid SSN."></lfg-error>

                <lfg-error *ngIf="(billingQuestionForm.get(data.fieldId).invalid && billingQuestionForm.get(data.fieldId).touched) &&(billingQuestionForm.get(data.fieldId).errors != null && billingQuestionForm.get(data.fieldId).errors.notUnique)" message="This SSN has already been provided, please review and edit"></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for radio question -->
            <div class="lfg-customRadio-field" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.RADIO"
              [ngClass]="{'radio--err': (billingQuestionForm.get(data.fieldId).touched && billingQuestionForm.get(data.fieldId).invalid)}">
              <span class="lfg-radio-section">
                <p class="question-radio_text">{{ data.questionText + (data.required == 'true' ? '' : ' (optional)') }}</p>
                <div class="custom-radio-button">
                  <input [attr.aria-label]="data.questionOption[0].description" tabindex="0" id="yes_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[0].value }}" required="{{data.required}}" (change)="onRadioChange(data)"/>
                  <label for="yes_{{ data.fieldId }}">{{ data.questionOption[0].description }}</label>
                  <input [attr.aria-label]="data.questionOption[1].description" tabindex="0" id="no_{{ data.fieldId }}" type="radio" formControlName="{{ data.fieldId }}" name="{{ data.fieldId }}" value="{{ data.questionOption[1].value }}" required="{{data.required}}" (change)="onRadioChange(data)">
                  <label for="no_{{ data.fieldId }}">{{ data.questionOption[1].description }}</label>
                </div>
              </span>
              <lfg-error *ngIf="billingQuestionForm.get(data.fieldId).touched && billingQuestionForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data) }}"></lfg-error>
            </div>

            <!-- Form Element for date  -->
            <div class="form--control lfg-cal" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.DOB">
              <lfg-calendar (focusin)="validateOnFocus(data, 'in')" id="{{data.fieldId}}" (onChange)="onDOBChange($event)" (focusout)="validateOnFocus(data, 'out')" lfgCheckValidDate [formControl]="billingQuestionForm.get(data.fieldId)" [config]="utilsService.getCalendarConfig(data, dateConfig)" [class.calendar--err]="(billingQuestionForm.get(data.fieldId).touched && billingQuestionForm.get(data.fieldId).invalid)" [required]="data.required == 'true'">
                  <lfg-error *ngIf="billingQuestionForm.get(data.fieldId).touched && billingQuestionForm.get(data.fieldId).invalid " message="{{ getErrorMsg(data) }}"></lfg-error>
              </lfg-calendar>
            </div>

            <!-- Form element for Drop Down -->
            <div class="form--control form-dropDown" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.SELECT">
              <lfg-dropdown formControlName="{{ data.fieldId }}" id="{{displayIdCreation(data)}}_{{ind}}" [options]="alldropDownOptions[data.fieldId]" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(data, ind)" [required]="data.required == 'true'"
              [ngClass]="{'dropdown--err': (billingQuestionForm.get(data.fieldId).touched && billingQuestionForm.get(data.fieldId).invalid)}">
              </lfg-dropdown>
              <lfg-error *ngIf="billingQuestionForm.get(data.fieldId).touched && billingQuestionForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data, billingQuestionForm.get(data.fieldId)) }}"></lfg-error>
            </div>

            <lfg-alert-message *ngIf="marketName === 'MGFA' && billingQuestionForm.get(data.fieldId)?.value != '' && data.xmlTag === billingKeys.PremiumMode && minAllowableAmountError" type="error">The Minimum allowable amount for the Premium is ${{ minPremiumAmmountForMode }} if the Mode is {{ premiumMode }}.</lfg-alert-message>

            <lfg-alert-message *ngIf="(marketName === 'MGMA22' || marketName === 'MGMA24') && billingQuestionForm.get(data.fieldId)?.value != '' && data.xmlTag === billingKeys.PremiumMode && minAllowableAmountError" type="error">The Minimum allowable amount for the Modal Planned Premium is  ${{ minPremiumAmmountForMode }} if the Premium Mode is {{ premiumMode }}.</lfg-alert-message>

            <!-- Form element for phone number -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.PHONE">
              <lfg-input-wrap>
                <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="data.required == 'true'">
                <lfg-error *ngIf="(billingQuestionForm.get(data.fieldId).touched && billingQuestionForm.get(data.fieldId).invalid)" message="{{ getErrorMsg(data) }}"></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for email  -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.EMAIL">
              <lfg-input-wrap>
                <input lfgInput placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" name="{{ displayTextLabel(data) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="data.required == 'true'">

                <lfg-error *ngIf="billingQuestionForm.get(data.fieldId).touched && billingQuestionForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Form element for alphanumeric  -->
            <div class="form--control" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
              <lfg-input-wrap>
                <input lfgInput aria-live="off" name="{{ displayTextLabel(data) }}" placeholder="{{ addCorrectPlaceHolder(data, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ data.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="data.maxlength" [required]="data.required == 'true'">

                <lfg-error *ngIf="billingQuestionForm.get(data.fieldId).touched && billingQuestionForm.get(data.fieldId).invalid" message="{{ getErrorMsg(data) }}"></lfg-error>
              </lfg-input-wrap>
            </div>

            <!-- Question Info -->
            <div class="question-info" *ngIf="data.controlTypeDesc == appConfig.fieldTypes.HDR && data.subText == 'Information' && data.questionText.toLowerCase().indexOf(premiumAndBillingMsgs.isMoreThanOnePolicy) == -1">
              <lfg-alert-message type="info">{{ data.questionText }}</lfg-alert-message>
            </div>

            <!-- Nestion question Level 1 -->
            <div *ngIf="data.hasReflexive == 'true' && billingQuestionForm.get(data.fieldId).value as parentAnswer" [ngClass]="{'nested-questions': checkIfQuestionDisplayed(data) }">
              <ng-container *ngFor="let primaryChildL1 of data.reflexiveQuestionAL; index as primaryChildL1ix">
                <ng-container *ngIf="nestedQuestionCheckBillingInfo(data, primaryChildL1, parentAnswer)">

                  <!-- element to switch controls to new line -->
                  <div class="full-width-ele" *ngIf="pushFieldToNewLine.indexOf((primaryChildL1.questionText).toLowerCase()) !== -1">
                  </div>

                  <!-- Questions headers -->
                  <div class="questions-header" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.HDR">
                  <h2>{{ primaryChildL1.questionText }}</h2>
                  </div>

                  <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                  <div class="questionText" *ngIf="addCorrectPlaceHolder(primaryChildL1, 'display')">
                    {{ addCorrectPlaceHolder(primaryChildL1, 'question')  }}
                  </div>

                  <!-- USA address -->
                  <div class="questions-header" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
                    <h2>{{ primaryChildL1.questionText }}</h2>
                  </div>

                  <!-- Form element for text  -->
                  <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.TEXT && (primaryChildL1.notes !== 'ITES_FORMAT_AS_ZIPCODE' && primaryChildL1.notes !== 'ITES_FORMAT_AS_CURRENCY') " [ngClass]="{'resetError': !billingQuestionForm.get(primaryChildL1.fieldId).errors}">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL1) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" type='text' formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="primaryChildL1.answerTextType === 'Numeric only' ? appConfig.customAttr['Numeric'] : 'text'" [maxlength]="primaryChildL1.maxLength >= 999 ? 30 : primaryChildL1.maxLength" [minlength]="primaryChildL1.questionText.toLowerCase().indexOf('routing number') !== -1 ? 9 : 0" [required]="primaryChildL1.required == 'true'">
                      <lfg-error *ngIf="billingQuestionForm.get(primaryChildL1.fieldId).touched && billingQuestionForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <lfg-alert-message *ngIf="marketName == 'MGFA' && primaryChildL1.xmlTag === billingKeys.numberYearsPayable && billingQuestionForm.get(primaryChildL1.fieldId)?.value != '' && (billingQuestionForm.get(primaryChildL1.fieldId)?.value < 2 || billingQuestionForm.get(primaryChildL1.fieldId)?.value > 10)" type="error">The allowable Years Payable value must be between 2 and 10 years.</lfg-alert-message>

                  <lfg-alert-message *ngIf="marketName != 'MGFA' && primaryChildL1.xmlTag === billingKeys.numberYearsPayable && billingQuestionForm.get(primaryChildL1.fieldId)?.value != '' && (billingQuestionForm.get(primaryChildL1.fieldId)?.value < 2 || billingQuestionForm.get(primaryChildL1.fieldId)?.value > 40)" type="error">The allowable Years Payable value must be between 2 and 40 years.</lfg-alert-message>

                  <!-- Form element for currency  -->
                  <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.TEXT && primaryChildL1.notes == 'ITES_FORMAT_AS_CURRENCY'">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" type='text' formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" name="{{displayTextLabel(primaryChildL1)}}" appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" maxlength='9' [required]="primaryChildL1.required == 'true'">

                      <lfg-error *ngIf="billingQuestionForm.get(primaryChildL1.fieldId).touched && billingQuestionForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1, billingQuestionForm.get(primaryChildL1.fieldId)) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for zip  -->
                  <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc === appConfig.fieldTypes.TEXT && primaryChildL1.notes === 'ITES_FORMAT_AS_ZIPCODE'">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" mask='00000' id="{{primaryChildL1.fieldId}}"
                        placeholder="{{ utilsService.getCorrectContent(primaryChildL1, 'placeHolder') }}" appNoSpecialSymbols
                        type='text' [formControl]='billingQuestionForm.get(primaryChildL1.fieldId)'
                        autocomplete="off" appValidateOnBlur value="" maxlength='5'
                        [ngClass]="{'inp-txt--err': (billingQuestionForm.get(primaryChildL1.fieldId).touched && billingQuestionForm.get(primaryChildL1.fieldId).invalid) }"
                        [required]="primaryChildL1.required === 'true'">

                      <lfg-error
                        *ngIf="billingQuestionForm.get(primaryChildL1.fieldId).touched && billingQuestionForm.get(primaryChildL1.fieldId).invalid"
                        message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for alphanumeric  -->
                  <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL1) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="primaryChildL1.maxLength" [required]="primaryChildL1.required == 'true'">

                      <lfg-error *ngIf="billingQuestionForm.get(primaryChildL1.fieldId).touched && billingQuestionForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for phone number -->
                  <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.PHONE">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="primaryChildL1.required == 'true'">
                      <lfg-error *ngIf="(billingQuestionForm.get(primaryChildL1.fieldId).touched && billingQuestionForm.get(primaryChildL1.fieldId).invalid)" message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for email  -->
                  <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.EMAIL">
                    <lfg-input-wrap>
                      <input lfgInput placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" name="{{ displayTextLabel(primaryChildL1) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ primaryChildL1.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="primaryChildL1.required == 'true'">

                      <lfg-error *ngIf="billingQuestionForm.get(primaryChildL1.fieldId).touched && billingQuestionForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for Drop Down -->
                  <div class="form--control form-dropDown" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.SELECT" [ngClass]="{'drop-down-medium': primaryChildL1.questionText.length > 32}">
                    <lfg-dropdown formControlName="{{ primaryChildL1.fieldId }}" id="{{displayIdCreation(primaryChildL1)}}_{{primaryChildL1ix}}" [options]="alldropDownOptions[primaryChildL1.fieldId]" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(primaryChildL1, primaryChildL1ix)" [required]="primaryChildL1.required == 'true'"
                    [ngClass]="{'dropdown--err': (billingQuestionForm.get(primaryChildL1.fieldId).touched && billingQuestionForm.get(primaryChildL1.fieldId).invalid)}">
                    </lfg-dropdown>
                    <lfg-error *ngIf="billingQuestionForm.get(primaryChildL1.fieldId).touched && billingQuestionForm.get(primaryChildL1.fieldId).invalid" message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                  </div>

                  <!-- Form element for SSN -->
                  <div class="form--control" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.SSN">
                    <lfg-input-wrap>
                      <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL1) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL1, 'placeHolder') }}" mask='AAA-AA-0000' [formControl]='billingQuestionForm.get(primaryChildL1.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur maxlength='11' [required]="primaryChildL1.required == 'true'">

                      <lfg-error *ngIf="(billingQuestionForm.get(primaryChildL1.fieldId).touched && billingQuestionForm.get(primaryChildL1.fieldId).invalid) && ( billingQuestionForm.get(primaryChildL1.fieldId).errors == null || (billingQuestionForm.get(primaryChildL1.fieldId).errors != null && !billingQuestionForm.get(primaryChildL1.fieldId).errors.notUnique))" message="Please enter a valid SSN."></lfg-error>

                      <lfg-error *ngIf="(billingQuestionForm.get(primaryChildL1.fieldId).invalid && billingQuestionForm.get(primaryChildL1.fieldId).touched) &&(billingQuestionForm.get(primaryChildL1.fieldId).errors != null && billingQuestionForm.get(primaryChildL1.fieldId).errors.notUnique)" message="This SSN has already been provided, please review and edit"></lfg-error>
                    </lfg-input-wrap>
                  </div>

                  <!-- Form element for radio question -->
                  <div class="lfg-customRadio-field" *ngIf="primaryChildL1.controlTypeDesc == appConfig.fieldTypes.RADIO"
                  [ngClass]="{'radio--err': (billingQuestionForm.get(primaryChildL1.fieldId).touched && billingQuestionForm.get(primaryChildL1.fieldId).invalid)}">
                    <span class="lfg-radio-section">
                      <p class="question-radio_text">{{ primaryChildL1.questionText + (primaryChildL1.required == 'true' ? '' : ' (optional)') }}</p>
                      <div class="custom-radio-button">
                        <input [attr.aria-label]="primaryChildL1.questionOption[0].description" tabindex="0" id="yes_{{ primaryChildL1.fieldId }}" type="radio" formControlName="{{ primaryChildL1.fieldId }}" name="{{ primaryChildL1.fieldId }}" value="{{ primaryChildL1.questionOption[0].value }}" required="{{primaryChildL1.required}}" (change)="onRadioChange(primaryChildL1)"/>
                        <label for="yes_{{ primaryChildL1.fieldId }}">{{ primaryChildL1.questionOption[0].description }}</label>
                        <input [attr.aria-label]="primaryChildL1.questionOption[1].description" tabindex="0" id="no_{{ primaryChildL1.fieldId }}" type="radio" formControlName="{{ primaryChildL1.fieldId }}" name="{{ primaryChildL1.fieldId }}" value="{{ primaryChildL1.questionOption[1].value }}" required="{{primaryChildL1.required}}" (change)="onRadioChange(primaryChildL1)">
                        <label for="no_{{ primaryChildL1.fieldId }}">{{ primaryChildL1.questionOption[1].description }}</label>
                      </div>
                    </span>
                    <lfg-error *ngIf="billingQuestionForm.get(primaryChildL1.fieldId).touched && billingQuestionForm.get(primaryChildL1.fieldId).invalid " message="{{ getErrorMsg(primaryChildL1) }}"></lfg-error>
                  </div>

                  <!-- Nestion question Level 2 -->
                  <div *ngIf="primaryChildL1.hasReflexive == 'true' && billingQuestionForm.get(primaryChildL1.fieldId).value as parentAnswer" [ngClass]="{'nested-questions no-border_bottom': checkIfQuestionDisplayed(primaryChildL1) }">
                    <ng-container *ngFor="let primaryChildL2 of primaryChildL1.reflexiveQuestionAL; index as primaryChildL2ix">
                      <ng-container *ngIf="nestedQuestionCheckBillingInfo(primaryChildL1, primaryChildL2, parentAnswer)">

                        <!-- Questions headers -->
                        <div class="questions-header" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.HDR && (primaryChildL2.subText == null || primaryChildL2.subText == '')">
                          <h2>{{ primaryChildL2.questionText }}</h2>
                        </div>

                        <div class="questions-header" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.HDR && primaryChildL2.questionText !== 'Line' && primaryChildL2.subText == 'Error'">
                          <lfg-alert-message type="error">{{ primaryChildL2.questionText }}</lfg-alert-message>
                          </div>

                        <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                        <div class="questionText" *ngIf="addCorrectPlaceHolder(primaryChildL2, 'display')">
                          {{ addCorrectPlaceHolder(primaryChildL2, 'question')  }}
                        </div>

                        <!-- USA address -->
                        <div class="questions-header" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
                          <h2>{{ primaryChildL2.questionText }}</h2>
                        </div>

                        <!-- Form element for text  -->
                        <div class="form--control" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.TEXT && (primaryChildL2.notes !== 'ITES_FORMAT_AS_ZIPCODE' && primaryChildL2.notes !== 'ITES_FORMAT_AS_CURRENCY')" [ngClass]="{'form-control-medium': primaryChildL2.questionText === 'Full legal name of payor'}">
                          <lfg-input-wrap>
                            <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL2) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL2, 'placeHolder') }}" type='text' formControlName='{{ primaryChildL2.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="primaryChildL2.xmlTag == '/XML/Client/AppDetails/DriverLicenseNum' ? getCustomAttribute() : 'text'" [maxlength]="primaryChildL2.maxLength >= 999 ? 30 : primaryChildL2.maxLength" [required]="primaryChildL2.required == 'true'">
                            <lfg-error *ngIf="billingQuestionForm.get(primaryChildL2.fieldId).touched && billingQuestionForm.get(primaryChildL2.fieldId).invalid" message="{{ getErrorMsg(primaryChildL2) }}"></lfg-error>
                          </lfg-input-wrap>
                        </div>

                        <!-- Form element for currency  -->
                        <div class="form--control" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.TEXT && primaryChildL2.notes == 'ITES_FORMAT_AS_CURRENCY'">
                          <lfg-input-wrap>
                            <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(primaryChildL2, 'placeHolder') }}" type='text' formControlName='{{ primaryChildL2.fieldId }}' autocomplete="off" name="{{displayTextLabel(primaryChildL2)}}" appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols customAttr="numbersOnly" maxlength='9' [required]="primaryChildL2.required == 'true'">

                            <lfg-error *ngIf="billingQuestionForm.get(primaryChildL2.fieldId).touched && billingQuestionForm.get(primaryChildL2.fieldId).invalid" message="{{ getErrorMsg(primaryChildL2, billingQuestionForm.get(primaryChildL2.fieldId)) }}"></lfg-error>
                          </lfg-input-wrap>
                        </div>

                        <!-- Form element for zip  -->
                        <div class="form--control" *ngIf="primaryChildL2.controlTypeDesc === appConfig.fieldTypes.TEXT && primaryChildL2.notes === 'ITES_FORMAT_AS_ZIPCODE'">
                          <lfg-input-wrap>
                            <input lfgInput aria-live="off" mask='00000' id="{{primaryChildL2.fieldId}}"
                              placeholder="{{ utilsService.getCorrectContent(primaryChildL2, 'placeHolder') }}" appNoSpecialSymbols
                              type='text' [formControl]='billingQuestionForm.get(primaryChildL2.fieldId)'
                              autocomplete="off" appValidateOnBlur value="" maxlength='5'
                              [ngClass]="{'inp-txt--err': (billingQuestionForm.get(primaryChildL2.fieldId).touched && billingQuestionForm.get(primaryChildL2.fieldId).invalid) }"
                              [required]="primaryChildL2.required === 'true'">

                            <lfg-error
                              *ngIf="billingQuestionForm.get(primaryChildL2.fieldId).touched && billingQuestionForm.get(primaryChildL2.fieldId).invalid"
                              message="{{ getErrorMsg(primaryChildL2) }}"></lfg-error>
                          </lfg-input-wrap>
                        </div>

                        <!-- Form element for alphanumeric  -->
                        <div class="form--control" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                          <lfg-input-wrap>
                            <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL2) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL2, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ primaryChildL2.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="primaryChildL2.maxLength" [required]="primaryChildL2.required == 'true'">

                            <lfg-error *ngIf="billingQuestionForm.get(primaryChildL2.fieldId).touched && billingQuestionForm.get(primaryChildL2.fieldId).invalid" message="{{ getErrorMsg(primaryChildL2) }}"></lfg-error>
                          </lfg-input-wrap>
                        </div>

                        <!-- Form element for phone number -->
                        <div class="form--control" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.PHONE">
                          <lfg-input-wrap>
                            <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(primaryChildL2, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ primaryChildL2.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="primaryChildL2.required == 'true'">
                            <lfg-error *ngIf="(billingQuestionForm.get(primaryChildL2.fieldId).touched && billingQuestionForm.get(primaryChildL2.fieldId).invalid)" message="{{ getErrorMsg(primaryChildL2) }}"></lfg-error>
                          </lfg-input-wrap>
                        </div>

                        <!-- Form element for email  -->
                        <div class="form--control" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.EMAIL">
                          <lfg-input-wrap>
                            <input lfgInput placeholder="{{ addCorrectPlaceHolder(primaryChildL2, 'placeHolder') }}" name="{{ displayTextLabel(primaryChildL2) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ primaryChildL2.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="primaryChildL2.required == 'true'">

                            <lfg-error *ngIf="billingQuestionForm.get(primaryChildL2.fieldId).touched && billingQuestionForm.get(primaryChildL2.fieldId).invalid" message="{{ getErrorMsg(primaryChildL2) }}"></lfg-error>
                          </lfg-input-wrap>
                        </div>

                        <!-- Form element for Drop Down -->
                        <div class="form--control form-dropDown" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.SELECT" [ngClass]="{'drop-down-medium': primaryChildL2.questionText.length > 32}">
                          <lfg-dropdown formControlName="{{ primaryChildL2.fieldId }}" id="{{displayIdCreation(primaryChildL2)}}_{{primaryChildL2ix}}" [options]="alldropDownOptions[primaryChildL2.fieldId]" placeholder="{{ addCorrectPlaceHolder(primaryChildL2, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(primaryChildL2, primaryChildL2ix)" [required]="primaryChildL2.required == 'true'"
                          [ngClass]="{'dropdown--err': (billingQuestionForm.get(primaryChildL2.fieldId).touched && billingQuestionForm.get(primaryChildL2.fieldId).invalid)}">
                          </lfg-dropdown>
                          <lfg-error *ngIf="billingQuestionForm.get(primaryChildL2.fieldId).touched && billingQuestionForm.get(primaryChildL2.fieldId).invalid" message="{{ getErrorMsg(primaryChildL2) }}"></lfg-error>
                        </div>

                        <!-- Form element for SSN -->
                        <div class="form--control" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.SSN">
                          <lfg-input-wrap>
                            <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL2) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL2, 'placeHolder') }}" mask='AAA-AA-0000' [formControl]='billingQuestionForm.get(primaryChildL2.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur maxlength='11' [required]="primaryChildL2.required == 'true'">

                            <lfg-error *ngIf="(billingQuestionForm.get(primaryChildL2.fieldId).touched && billingQuestionForm.get(primaryChildL2.fieldId).invalid) && ( billingQuestionForm.get(primaryChildL2.fieldId).errors == null || (billingQuestionForm.get(primaryChildL2.fieldId).errors != null && !billingQuestionForm.get(primaryChildL2.fieldId).errors.notUnique))" message="Please enter a valid SSN."></lfg-error>

                            <lfg-error *ngIf="(billingQuestionForm.get(primaryChildL2.fieldId).invalid && billingQuestionForm.get(primaryChildL2.fieldId).touched) &&(billingQuestionForm.get(primaryChildL2.fieldId).errors != null && billingQuestionForm.get(primaryChildL2.fieldId).errors.notUnique)" message="This SSN has already been provided, please review and edit"></lfg-error>
                          </lfg-input-wrap>
                        </div>

                        <!-- Form element for TIN -->
                        <div class="form--control" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.TIN">
                          <lfg-input-wrap>
                            <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL2) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL2, 'placeHolder') }}" mask='AA-AAA0000' [formControl]='billingQuestionForm.get(primaryChildL2.fieldId)' autocomplete="off" appOnlyNumeric lfgStopPaste minlength='9' name='pwd' value="" type='password' appValidateOnBlur maxlength='11' [required]="primaryChildL2.required == 'true'" (input)="checkIfSSNIsValid(billingQuestionForm, grid.gridUniqueId)">
                            <lfg-error *ngIf="(billingQuestionForm.get(primaryChildL2.fieldId).touched && billingQuestionForm.get(primaryChildL2.fieldId).invalid) && ( billingQuestionForm.get(primaryChildL2.fieldId).errors == null || (billingQuestionForm.get(primaryChildL2.fieldId).errors != null && !billingQuestionForm.get(primaryChildL2.fieldId).errors.notUnique))" message="Please enter a valid TIN."></lfg-error>
                          </lfg-input-wrap>
                        </div>

                        <!-- Form element for radio question -->
                        <div class="lfg-customRadio-field" *ngIf="primaryChildL2.controlTypeDesc == appConfig.fieldTypes.RADIO"
                        [ngClass]="{'radio--err': (billingQuestionForm.get(primaryChildL2.fieldId).touched && billingQuestionForm.get(primaryChildL2.fieldId).invalid)}">
                          <span class="lfg-radio-section">
                            <p class="question-radio_text">{{ primaryChildL2.questionText + (primaryChildL2.required == 'true' ? '' : ' (optional)') }}</p>
                            <div class="custom-radio-button">
                              <input [attr.aria-label]="primaryChildL2.questionOption[0].description" tabindex="0" id="yes_{{ primaryChildL2.fieldId }}" type="radio" formControlName="{{ primaryChildL2.fieldId }}" name="{{ primaryChildL2.fieldId }}" value="{{ primaryChildL2.questionOption[0].value }}" required="{{primaryChildL2.required}}" (change)="onRadioChange(primaryChildL2)"/>
                              <label for="yes_{{ primaryChildL2.fieldId }}">{{ primaryChildL2.questionOption[0].description }}</label>
                              <input [attr.aria-label]="primaryChildL2.questionOption[1].description" tabindex="0" id="no_{{ primaryChildL2.fieldId }}" type="radio" formControlName="{{ primaryChildL2.fieldId }}" name="{{ primaryChildL2.fieldId }}" value="{{ primaryChildL2.questionOption[1].value }}" required="{{primaryChildL2.required}}" (change)="onRadioChange(primaryChildL2)">
                              <label for="no_{{ primaryChildL2.fieldId }}">{{ primaryChildL2.questionOption[1].description }}</label>
                            </div>
                          </span>
                          <lfg-error *ngIf="billingQuestionForm.get(primaryChildL2.fieldId).touched && billingQuestionForm.get(primaryChildL2.fieldId).invalid " message="{{ getErrorMsg(primaryChildL2) }}"></lfg-error>
                        </div>

                        <!-- Nestion question Level 3 -->
                        <div *ngIf="primaryChildL2.hasReflexive == 'true' && billingQuestionForm.get(primaryChildL2.fieldId).value as parentAnswer" [ngClass]="{'nested-questions no-border_bottom': checkIfQuestionDisplayed(primaryChildL2) }">
                          <ng-container *ngFor="let primaryChildL3 of primaryChildL2.reflexiveQuestionAL; index as primaryChildL3ix">
                            <ng-container *ngIf="nestedQuestionCheckBillingInfo(primaryChildL2, primaryChildL3, parentAnswer)">

                              <!-- element to switch controls to new line -->
                              <div class="full-width-ele" *ngIf="pushFieldToNewLine.indexOf((primaryChildL3.questionText).toLowerCase()) !== -1 && primaryChildL3.questionText !== 'Who is the accountholder?' ">
                              </div>

                              <!-- Questions headers -->
                              <div class="questions-header" *ngIf="primaryChildL3.controlTypeDesc == appConfig.fieldTypes.HDR">
                              <h2>{{ primaryChildL3.questionText }}</h2>
                              </div>

                              <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                              <div class="questionText" *ngIf="addCorrectPlaceHolder(primaryChildL3, 'display')">
                                {{ addCorrectPlaceHolder(primaryChildL3, 'question')  }}
                              </div>

                              <!-- USA address -->
                              <div class="questions-header" *ngIf="primaryChildL3.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
                                <h2>{{ primaryChildL3.questionText }}</h2>
                              </div>

                              <!-- Form element for text  -->
                              <div class="form--control" *ngIf="primaryChildL3.controlTypeDesc == appConfig.fieldTypes.TEXT && (primaryChildL3.notes !== 'ITES_FORMAT_AS_ZIPCODE')" [ngClass]="{'resetError': !billingQuestionForm.get(primaryChildL3.fieldId).errors}">
                                <lfg-input-wrap>
                                  <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL3) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL3, 'placeHolder') }}" type='text' formControlName='{{ primaryChildL3.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="primaryChildL3.xmlTag == '/XML/Client/AppDetails/DriverLicenseNum' ? getCustomAttribute() : (primaryChildL3.xmlTag == temporaryLifeInsuranceConstants.PACBankRoutingMICRNum || primaryChildL3.xmlTag == temporaryLifeInsuranceConstants.PACAccountNumber) ? appConfig.customAttr['Numeric'] : 'text'" [maxlength]="primaryChildL3.maxLength >= 999 ? 30 : primaryChildL3.maxLength"  minlength="{{ primaryChildL3.questionText.toLowerCase().indexOf('routing number') !== -1 ? 9 : 0 }}" [required]="primaryChildL3.required == 'true'">
                                  <lfg-error *ngIf="billingQuestionForm.get(primaryChildL3.fieldId).touched && billingQuestionForm.get(primaryChildL3.fieldId).invalid" message="{{ getErrorMsg(primaryChildL3) }}"></lfg-error>
                                </lfg-input-wrap>
                              </div>

                              <!-- Form element for zip  -->
                              <div class="form--control" *ngIf="primaryChildL3.controlTypeDesc === appConfig.fieldTypes.TEXT && primaryChildL3.notes === 'ITES_FORMAT_AS_ZIPCODE'">
                                <lfg-input-wrap>
                                  <input lfgInput aria-live="off" mask='00000' id="{{primaryChildL3.fieldId}}"
                                    placeholder="{{ utilsService.getCorrectContent(primaryChildL3, 'placeHolder') }}" appNoSpecialSymbols
                                    type='text' [formControl]='billingQuestionForm.get(primaryChildL3.fieldId)'
                                    autocomplete="off" appValidateOnBlur value="" maxlength='5'
                                    [ngClass]="{'inp-txt--err': (billingQuestionForm.get(primaryChildL3.fieldId).touched && billingQuestionForm.get(primaryChildL3.fieldId).invalid) }"
                                    [required]="primaryChildL3.required === 'true'">

                                  <lfg-error
                                    *ngIf="billingQuestionForm.get(primaryChildL3.fieldId).touched && billingQuestionForm.get(primaryChildL3.fieldId).invalid"
                                    message="{{ getErrorMsg(primaryChildL3) }}"></lfg-error>
                                </lfg-input-wrap>
                              </div>

                              <!-- Form element for alphanumeric  -->
                              <div class="form--control" *ngIf="primaryChildL3.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                                <lfg-input-wrap>
                                  <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL3) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL3, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ primaryChildL3.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="primaryChildL3.maxLength" [required]="primaryChildL3.required == 'true'">

                                  <lfg-error *ngIf="billingQuestionForm.get(primaryChildL3.fieldId).touched && billingQuestionForm.get(primaryChildL3.fieldId).invalid" message="{{ getErrorMsg(primaryChildL3) }}"></lfg-error>
                                </lfg-input-wrap>
                              </div>

                              <!-- Form element for phone number -->
                              <div class="form--control" *ngIf="primaryChildL3.controlTypeDesc == appConfig.fieldTypes.PHONE">
                                <lfg-input-wrap>
                                  <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(primaryChildL3, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ primaryChildL3.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="primaryChildL3.required == 'true'">
                                  <lfg-error *ngIf="(billingQuestionForm.get(primaryChildL3.fieldId).touched && billingQuestionForm.get(primaryChildL3.fieldId).invalid)" message="{{ getErrorMsg(primaryChildL3) }}"></lfg-error>
                                </lfg-input-wrap>
                              </div>

                              <!-- Form element for email  -->
                              <div class="form--control" *ngIf="primaryChildL3.controlTypeDesc == appConfig.fieldTypes.EMAIL">
                                <lfg-input-wrap>
                                  <input lfgInput placeholder="{{ addCorrectPlaceHolder(primaryChildL3, 'placeHolder') }}" name="{{ displayTextLabel(primaryChildL3) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ primaryChildL3.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="primaryChildL3.required == 'true'">

                                  <lfg-error *ngIf="billingQuestionForm.get(primaryChildL3.fieldId).touched && billingQuestionForm.get(primaryChildL3.fieldId).invalid" message="{{ getErrorMsg(primaryChildL3) }}"></lfg-error>
                                </lfg-input-wrap>
                              </div>

                              <!-- Form element for Drop Down -->
                              <div class="form--control form-dropDown" *ngIf="primaryChildL3.controlTypeDesc == appConfig.fieldTypes.SELECT" [ngClass]="{'drop-down-medium': primaryChildL3.questionText.length > 32}">
                                <lfg-dropdown formControlName="{{ primaryChildL3.fieldId }}" id="{{displayIdCreation(primaryChildL3)}}_{{primaryChildL3ix}}" [options]="alldropDownOptions[primaryChildL3.fieldId]" placeholder="{{ addCorrectPlaceHolder(primaryChildL3, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(primaryChildL3, primaryChildL3ix)" [required]="primaryChildL3.required == 'true'"
                                [ngClass]="{'dropdown--err': (billingQuestionForm.get(primaryChildL3.fieldId).touched && billingQuestionForm.get(primaryChildL3.fieldId).invalid)}">
                                </lfg-dropdown>
                                <lfg-error *ngIf="billingQuestionForm.get(primaryChildL3.fieldId).touched && billingQuestionForm.get(primaryChildL3.fieldId).invalid" message="{{ getErrorMsg(primaryChildL3) }}"></lfg-error>
                              </div>

                              <!-- Form element for radio question -->
                              <div class="lfg-customRadio-field" *ngIf="primaryChildL3.controlTypeDesc == appConfig.fieldTypes.RADIO"
                              [ngClass]="{'radio--err': (billingQuestionForm.get(primaryChildL3.fieldId).touched && billingQuestionForm.get(primaryChildL3.fieldId).invalid)}">
                                <span class="lfg-radio-section">
                                  <p class="question-radio_text">{{ primaryChildL3.questionText + (primaryChildL3.required == 'true' ? '' : ' (optional)') }}</p>
                                  <div class="custom-radio-button">
                                    <input [attr.aria-label]="primaryChildL3.questionOption[0].description" tabindex="0" id="yes_{{ primaryChildL3.fieldId }}" type="radio" formControlName="{{ primaryChildL3.fieldId }}" name="{{ primaryChildL3.fieldId }}" value="{{ primaryChildL3.questionOption[0].value }}" required="{{primaryChildL3.required}}" (change)="onRadioChange(primaryChildL3)"/>
                                    <label for="yes_{{ primaryChildL3.fieldId }}">{{ primaryChildL3.questionOption[0].description }}</label>
                                    <input [attr.aria-label]="primaryChildL3.questionOption[1].description" tabindex="0" id="no_{{ primaryChildL3.fieldId }}" type="radio" formControlName="{{ primaryChildL3.fieldId }}" name="{{ primaryChildL3.fieldId }}" value="{{ primaryChildL3.questionOption[1].value }}" required="{{primaryChildL3.required}}" (change)="onRadioChange(primaryChildL3)">
                                    <label for="no_{{ primaryChildL3.fieldId }}">{{ primaryChildL3.questionOption[1].description }}</label>
                                  </div>
                                </span>
                                <lfg-error *ngIf="billingQuestionForm.get(primaryChildL3.fieldId).touched && billingQuestionForm.get(primaryChildL3.fieldId).invalid " message="{{ getErrorMsg(primaryChildL3) }}"></lfg-error>
                              </div>

                              <!-- element to switch controls to new line -->
                              <div class="full-width-ele" *ngIf="primaryChildL3.questionText === 'Who is the accountholder?' &&pushFieldToNewLine.indexOf((primaryChildL3.questionText).toLowerCase()) !== -1 ">
                              </div>

                              <!-- Nestion question Level 4 -->
                              <div *ngIf="primaryChildL3.hasReflexive == 'true' && billingQuestionForm.get(primaryChildL3.fieldId).value as parentAnswer" [ngClass]="{'nested-questions no-border_bottom': checkIfQuestionDisplayed(primaryChildL3) }">
                                <ng-container *ngFor="let primaryChildL4 of primaryChildL3.reflexiveQuestionAL; index as primaryChildL3ix">
                                  <ng-container *ngIf="nestedQuestionCheckBillingInfo(primaryChildL3, primaryChildL4, parentAnswer)">

                                    <!-- element to switch controls to new line -->
                                    <div class="full-width-ele" *ngIf="pushFieldToNewLine.indexOf((primaryChildL4.questionText).toLowerCase()) !== -1">
                                    </div>

                                    <!-- Questions headers -->
                                    <div class="questions-header" *ngIf="primaryChildL4.controlTypeDesc == appConfig.fieldTypes.HDR">
                                    <h2>{{ primaryChildL4.questionText }}</h2>
                                    </div>

                                    <!-- show question if text is long for all feilds except hdr, radio, checkbox -->
                                    <div class="questionText" *ngIf="addCorrectPlaceHolder(primaryChildL4, 'display')">
                                      {{ addCorrectPlaceHolder(primaryChildL4, 'question')  }}
                                    </div>

                                    <!-- USA address -->
                                    <div class="questions-header" *ngIf="primaryChildL4.controlTypeDesc == appConfig.fieldTypes.USAADDRESS">
                                      <h2>{{ primaryChildL4.questionText }}</h2>
                                    </div>

                                    <!-- Form element for text  -->
                                    <div class="form--control" *ngIf="primaryChildL4.controlTypeDesc == appConfig.fieldTypes.TEXT && (primaryChildL4.notes !== 'ITES_FORMAT_AS_ZIPCODE')" [ngClass]="{'resetError': !billingQuestionForm.get(primaryChildL4.fieldId).errors}">
                                      <lfg-input-wrap>
                                        <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL4) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL4, 'placeHolder') }}" type='text' formControlName='{{ primaryChildL4.fieldId }}' autocomplete="off" appValidateOnBlur appNoSpecialSymbols [attr.customAttr]="primaryChildL4.xmlTag == '/XML/Client/AppDetails/DriverLicenseNum' ? getCustomAttribute() : 'text'" [maxlength]="primaryChildL4.maxLength >= 999 ? 30 : primaryChildL4.maxLength" [required]="primaryChildL4.required == 'true'">
                                        <lfg-error *ngIf="billingQuestionForm.get(primaryChildL4.fieldId).touched && billingQuestionForm.get(primaryChildL4.fieldId).invalid" message="{{ getErrorMsg(primaryChildL4) }}"></lfg-error>
                                      </lfg-input-wrap>
                                    </div>

                                    <!-- Form element for zip  -->
                                    <div class="form--control" *ngIf="primaryChildL4.controlTypeDesc === appConfig.fieldTypes.TEXT && primaryChildL4.notes === 'ITES_FORMAT_AS_ZIPCODE'">
                                      <lfg-input-wrap>
                                        <input lfgInput aria-live="off" mask='00000' id="{{primaryChildL4.fieldId}}"
                                          placeholder="{{ utilsService.getCorrectContent(primaryChildL4, 'placeHolder') }}" appNoSpecialSymbols
                                          type='text' [formControl]='billingQuestionForm.get(primaryChildL4.fieldId)'
                                          autocomplete="off" appValidateOnBlur value="" maxlength='5'
                                          [ngClass]="{'inp-txt--err': (billingQuestionForm.get(primaryChildL4.fieldId).touched && billingQuestionForm.get(primaryChildL4.fieldId).invalid) }"
                                          [required]="primaryChildL4.required === 'true'">

                                        <lfg-error
                                          *ngIf="billingQuestionForm.get(primaryChildL4.fieldId).touched && billingQuestionForm.get(primaryChildL4.fieldId).invalid"
                                          message="{{ getErrorMsg(primaryChildL4) }}"></lfg-error>
                                      </lfg-input-wrap>
                                    </div>

                                    <!-- Form element for alphanumeric  -->
                                    <div class="form--control" *ngIf="primaryChildL4.controlTypeDesc == appConfig.fieldTypes.ALPHANUMERIC">
                                      <lfg-input-wrap>
                                        <input lfgInput aria-live="off" name="{{ displayTextLabel(primaryChildL4) }}" placeholder="{{ addCorrectPlaceHolder(primaryChildL4, 'placeHolder') }}" appNoSpecialSymbols customAttr="alphaNumeric" type='text' formControlName='{{ primaryChildL4.fieldId }}' autocomplete="off" name="" appValidateOnBlur value="" [maxlength]="primaryChildL4.maxLength" [required]="primaryChildL4.required == 'true'">

                                        <lfg-error *ngIf="billingQuestionForm.get(primaryChildL4.fieldId).touched && billingQuestionForm.get(primaryChildL4.fieldId).invalid" message="{{ getErrorMsg(primaryChildL4) }}"></lfg-error>
                                      </lfg-input-wrap>
                                    </div>

                                    <!-- Form element for phone number -->
                                    <div class="form--control" *ngIf="primaryChildL4.controlTypeDesc == appConfig.fieldTypes.PHONE">
                                      <lfg-input-wrap>
                                        <input lfgInput aria-live="off" placeholder="{{ addCorrectPlaceHolder(primaryChildL4, 'placeHolder') }}" mask='(000) 000-0000' type='text' formControlName='{{ primaryChildL4.fieldId }}' autocomplete="off" name="" value="" appValidateOnBlur maxlength='16' [required]="primaryChildL4.required == 'true'">
                                        <lfg-error *ngIf="(billingQuestionForm.get(primaryChildL4.fieldId).touched && billingQuestionForm.get(primaryChildL4.fieldId).invalid)" message="{{ getErrorMsg(primaryChildL4) }}"></lfg-error>
                                      </lfg-input-wrap>
                                    </div>

                                    <!-- Form element for email  -->
                                    <div class="form--control" *ngIf="primaryChildL4.controlTypeDesc == appConfig.fieldTypes.EMAIL">
                                      <lfg-input-wrap>
                                        <input lfgInput placeholder="{{ addCorrectPlaceHolder(primaryChildL4, 'placeHolder') }}" name="{{ displayTextLabel(primaryChildL4) }}" aria-live="off" type='text' maxlength='50' formControlName='{{ primaryChildL4.fieldId }}' autocomplete="off" name="" appValidateOnBlur appNoSpecialSymbols customAttr="email" [required]="primaryChildL4.required == 'true'">

                                        <lfg-error *ngIf="billingQuestionForm.get(primaryChildL4.fieldId).touched && billingQuestionForm.get(primaryChildL4.fieldId).invalid" message="{{ getErrorMsg(primaryChildL4) }}"></lfg-error>
                                      </lfg-input-wrap>
                                    </div>

                                    <!-- Form element for Drop Down -->
                                    <div class="form--control form-dropDown" *ngIf="primaryChildL4.controlTypeDesc == appConfig.fieldTypes.SELECT" [ngClass]="{'drop-down-medium': primaryChildL4.questionText.length > 32}">
                                      <lfg-dropdown formControlName="{{ primaryChildL4.fieldId }}" id="{{displayIdCreation(primaryChildL4)}}_{{primaryChildL4ix}}" [options]="alldropDownOptions[primaryChildL4.fieldId]" placeholder="{{ addCorrectPlaceHolder(primaryChildL4, 'placeHolder') }}" (selectionChanged)="handleSelectionChange(primaryChildL4, primaryChildL4ix)" [required]="primaryChildL4.required == 'true'"
                                      [ngClass]="{'dropdown--err': (billingQuestionForm.get(primaryChildL4.fieldId).touched && billingQuestionForm.get(primaryChildL4.fieldId).invalid)}">
                                      </lfg-dropdown>
                                      <lfg-error *ngIf="billingQuestionForm.get(primaryChildL4.fieldId).touched && billingQuestionForm.get(primaryChildL4.fieldId).invalid" message="{{ getErrorMsg(primaryChildL4) }}"></lfg-error>
                                    </div>

                                    <!-- Form element for radio question -->
                                    <div class="lfg-customRadio-field" *ngIf="primaryChildL4.controlTypeDesc == appConfig.fieldTypes.RADIO"
                                    [ngClass]="{'radio--err': (billingQuestionForm.get(primaryChildL4.fieldId).touched && billingQuestionForm.get(primaryChildL4.fieldId).invalid)}">
                                      <span class="lfg-radio-section">
                                        <p class="question-radio_text">{{ primaryChildL4.questionText + (primaryChildL4.required == 'true' ? '' : ' (optional)') }}</p>
                                        <div class="custom-radio-button">
                                          <input [attr.aria-label]="primaryChildL4.questionOption[0].description" tabindex="0" id="yes_{{ primaryChildL4.fieldId }}" type="radio" formControlName="{{ primaryChildL4.fieldId }}" name="{{ primaryChildL4.fieldId }}" value="{{ primaryChildL4.questionOption[0].value }}" required="{{primaryChildL4.required}}" (change)="onRadioChange(primaryChildL4)"/>
                                          <label for="yes_{{ primaryChildL4.fieldId }}">{{ primaryChildL4.questionOption[0].description }}</label>
                                          <input [attr.aria-label]="primaryChildL4.questionOption[1].description" tabindex="0" id="no_{{ primaryChildL4.fieldId }}" type="radio" formControlName="{{ primaryChildL4.fieldId }}" name="{{ primaryChildL4.fieldId }}" value="{{ primaryChildL4.questionOption[1].value }}" required="{{primaryChildL4.required}}" (change)="onRadioChange(primaryChildL4)">
                                          <label for="no_{{ primaryChildL4.fieldId }}">{{ primaryChildL4.questionOption[1].description }}</label>
                                        </div>
                                      </span>
                                      <lfg-error *ngIf="billingQuestionForm.get(primaryChildL4.fieldId).touched && billingQuestionForm.get(primaryChildL4.fieldId).invalid " message="{{ getErrorMsg(primaryChildL4) }}"></lfg-error>
                                    </div>


                                  </ng-container>
                                </ng-container>
                              </div>


                            </ng-container>
                          </ng-container>
                        </div>

                      </ng-container>
                    </ng-container>
                  </div>

                </ng-container>
              </ng-container>
            </div>
             <div class="section-divider" *ngIf="data.xmlTag != billingKeys.numberYearsPayable" [ngClass]="{'required': data.xmlTag == billingKeys.payorSameAsType}"></div>
          </ng-container>
        </form>
      </section>
    </div>
  </section>
