import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { formatCurrency } from '@angular/common';
import * as _ from 'lodash';

import { ClipboardService } from '@ng/lfg-common-utilities';
import { LfgLoaderService } from '@ng/lfg-loader';
import { DisableStyle } from '@ng/lfg-calendar';

import { FormGeneratorService } from 'src/app/shared/services/form-generator.service';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { AgentAndFirmService } from 'src/app/shared/services/agent-and-firm.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { TabDetailsService } from 'src/app/shared/services/tab-details.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { PrimaryInsuredConstant, ErrorMessage, TemporaryLifeInsuranceConstants, AgentReportMsges,
  AgentReportKeys, LifeSutabilityZeroCheckKeys, KeysForIllustrationConditionals,
         LtcSuitabilityMsges, Products, ltcSuitabilityConstants, policyInfoConstant, EFTKeys, Message, policyInfoMsg } from 'src/config/constants';
import { Question } from 'src/app/shared/models/casePage.model';
import { ApplicationConfig } from 'src/config/app.config';
import { PAGE_GROUP_NAME } from 'src/config/page.config';
import { FunctionalityConfig } from 'src/config/delegationFunctionality.config';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-page-question',
  templateUrl: './page-question.component.html',
  styleUrls: ['./page-question.component.scss'],
})
export class PageQuestionComponent implements OnInit {
  @Input() disableForm: boolean;
  questionsData;

  @Input() userAccessDetails: FunctionalityConfig;
  @Input()
  set questions(parentData: any) {
    // every time the data from the parent changes this will run
    this.questionsData = parentData;
  }
  get questions(): any {
    return this.questionsData;
  }
  @Output() formStatus: EventEmitter<any> = new EventEmitter();
  @Output() checkForWetSignature: EventEmitter<string> = new EventEmitter();

  pageQuestionData: Question[] = [];
  pageQuestionForm: FormGroup;
  formValid = true;
  formHasNoErrors = true;
  placeholders = {};
  dateConfig = {
    id: 'dob',
    minDate: new Date('01/01/1900'),
    maxDate: new Date(),
    defaultDate: null,
    placeholder: 'Date',
    disableStyle: DisableStyle.locked,
  };
  calculatedAge: number;
  alldropDownOptions = {};
  setTimeFocus = null;
  isTabVisited: boolean;
  errorMessage = ErrorMessage.UNANSWERED_QUES_ERROR;
  greenCardErrorMessage = ErrorMessage.GREEN_CARD_HOLDER_ERROR;
  noGreenCardholder = false;
  pushFieldToNewLine = ['primary phone number', 'secondary phone number', 'proposed insured email address', 'who is the accountholder?', 'total annual rider charges:'];
  PAGE_GROUP_NAME = PAGE_GROUP_NAME;
  TIAConstants = TemporaryLifeInsuranceConstants;
  allState;
  primaryAgentEmail = '';
  caseManagerEmail;
  doYouAttestIndFieldIdValue;
  agentReportMsges = AgentReportMsges;
  agentReportKeys = AgentReportKeys;
  lifeSutabilityZeroCheckKeys = LifeSutabilityZeroCheckKeys;
  zeroCheckFlag = false;
  zeroCheckObj: any = {};
  declineDisclosure = false;
  marriedCivilUnionKey = KeysForIllustrationConditionals.marriedCivilUnion
  suitabilityIndMsg = false;
  inflationProtectionMsg = false;
  ltcSuitabilityMsges = LtcSuitabilityMsges;
  showLoader = false;
  previousCaseMangerEmail = '';
  previous1CaseMangerEmail = '';
  ltcSuitabilityXmlTags = ltcSuitabilityConstants;
  isEFT = false;
  eftExistsMsg = Message.TIA_EFT_MSG;
  delegateMsg = policyInfoMsg.DELEGATE_INFO;

  constructor(
    private fb: FormBuilder,
    public appConfig: ApplicationConfig,
    private formgeneratorService: FormGeneratorService,
    private commonService: CommonService,
    private utilsService: UtilsService,
    private userService: UserDetailsService,
    private productService: ProductDetailsService,
    private defaultPageLoadService: DefaultPageload,
    private agentService: AgentAndFirmService,
    private clipboardService: ClipboardService,
    private pageLoadService: DefaultPageload,
    private loaderService: LfgLoaderService,
    private tabDetailsService: TabDetailsService,
    private changeDetectorRef : ChangeDetectorRef 
  ) { }

  ngOnInit(): void {
    this.addLoader(true);
    if (this.questionsData) {
      this.buildFormData();
      if (this.questionsData.page_group_name === PAGE_GROUP_NAME.PRIMARY_INSURED_PAGE) {
        this.updateFormData();
      }
      this.isTabVisited = this.defaultPageLoadService.updateFormErrors(this.pageQuestionForm);
      if (this.isTabVisited) {
        this.declineDisclosure = this.clipboardService.get('declineDisclosure');
      }
      if (this.questionsData.page_group_name === PAGE_GROUP_NAME.AGENT_REPORT_PAGE) {
        const doYouAttestIndObj = this.pageQuestionData.filter(data => data.xmlTag === this.agentReportKeys.doYouAttestInd);
        this.agentReportIAttestCheck(doYouAttestIndObj[0]);
      }
      this.isEFT = this.questionsData.isEFT;
      this.allState = this.agentService.getAgentProfile()?.allStateFlag;
      this.caseManagerEmail = this.clipboardService.get('caseManagerEmail');
      this.primaryAgentEmail = this.clipboardService.get('primaryAgentEmail') || '';
      this.previousCaseMangerEmail = this.clipboardService.get('previousCaseMangerEmail') || '';
      this.previous1CaseMangerEmail = this.clipboardService.get('previous1CaseMangerEmail') || '';
      this.initializeChecks();
      this.emitForWetSignatureCheck();
      this.defaultPageLoadService.logPageLoad();
    }
    this.addLoader(false);
  }

  initializeChecks(): void {
    if (this.clipboardService.get('zeroCheckObj'))  this.zeroCheckObj = this.clipboardService.get('zeroCheckObj');
    this.zeroCheckFlag = this.clipboardService.get('zeroCheckFlag');
    if (this.questionsData?.page_group_name === PAGE_GROUP_NAME.LTC_SUITABILITY_PAGE) {
      this.ltcSuitabilityConditions();
    }
    if (this.questionsData?.page_group_name === PAGE_GROUP_NAME.POLICY_INFO_PAGE) {
      this.policyInfoConditions();
    }
    this.defaultPageLoadService.disableFormIfLocked(this.pageQuestionForm, this.disableForm);
  }

  ltcSuitabilityConditions(): void {
    if (this.productService.getMarketName() !== Products.MGFA) {
      this.declineDisclosure = true;
    }
    const disclosureIndObj = this.pageQuestionData.filter(data => data.xmlTag === ltcSuitabilityConstants.financialSituation);
    const financialAttestationFieldId = this.utilsService.getFieldId(this.pageQuestionData, ltcSuitabilityConstants.financialSituationDescribed);
    const declineDisclosureFieldId = this.utilsService.getFieldId(this.pageQuestionData, ltcSuitabilityConstants.declineDisclosureInd);
    if (this.pageQuestionForm.get(disclosureIndObj[0].fieldId).value === true) {
      this.pageQuestionForm.get(financialAttestationFieldId)?.setValue(false);
      this.pageQuestionForm.get(financialAttestationFieldId)?.disable();
    }

    const financialAttestationObj = this.pageQuestionData.filter(data => data.xmlTag === ltcSuitabilityConstants.financialSituationDescribed);
    if (this.pageQuestionForm.get(financialAttestationObj[0].fieldId).value === true) {
      this.pageQuestionForm.get(declineDisclosureFieldId)?.setValue(false);
      this.pageQuestionForm.get(declineDisclosureFieldId)?.disable();
    }

    if (this.pageQuestionForm.get(declineDisclosureFieldId).value === true) {
      this.declineDisclosure = true;
    }

    const suitabilityIndFieldId = this.utilsService.getFieldId(this.pageQuestionData, ltcSuitabilityConstants.suitabilityInd);
    if (this.pageQuestionForm.get(suitabilityIndFieldId).value === true) {
      this.suitabilityIndMsg = true;
    }
    const inflationProtectionFieldId = this.utilsService.getFieldId(this.pageQuestionData, ltcSuitabilityConstants.inflationProtectionInd);
    this.inflationProtectionCheck(inflationProtectionFieldId);
  }

  policyInfoConditions(): void {
    const marketName = this.productService.getMarketName();

    const LTCBenefitsRiderFieldId = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.ltcBenefitsRider);
    this.pageQuestionForm.get(LTCBenefitsRiderFieldId)?.setValue(true);
    this.pageQuestionForm.get(LTCBenefitsRiderFieldId)?.disable();

    const LTCBenefitsRiderDurationFieldId = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.ltcBenefitsRiderDuration);
    if (marketName === Products.MGMA || marketName === Products.MGMA22 || marketName === Products.MGMA24) {
      this.pageQuestionForm.get(LTCBenefitsRiderDurationFieldId)?.setValue({label: '3 Years Fixed', value: '3 Years Fixed{47}3 Years Fixed'});
      setTimeout(() => {
        this.pageQuestionForm.get(LTCBenefitsRiderDurationFieldId)?.disable();
      }, 100);
    }
    setTimeout(() => {
      this.setLTCBRInflationProtectionValue();
    }, 100);

    const LTCAcceleratedBenefitsFieldId = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.ltcAcceleratedBenefits);
    this.pageQuestionForm.get(LTCAcceleratedBenefitsFieldId)?.setValue(true);
    this.pageQuestionForm.get(LTCAcceleratedBenefitsFieldId)?.disable();

    const LTCAcceleratedBenefitsDurationFieldId = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.ltcAcceleratedBenefitsDuration);
    this.pageQuestionForm.get(LTCAcceleratedBenefitsDurationFieldId)?.setValue('2years')
    this.pageQuestionForm.get(LTCAcceleratedBenefitsDurationFieldId)?.disable();

    this.setLTCExtensionBenefitsRider();

    this.setvalueProtectionEndorsementType();

    const valueProtectionRiderFieldId = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.valueProtectionRider);
    this.pageQuestionForm.get(valueProtectionRiderFieldId)?.setValue(true)
    this.pageQuestionForm.get(valueProtectionRiderFieldId)?.disable();

    const LTCABRTotalMonthlyField = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.ltcABRTotalMonthly);
    this.pageQuestionForm.get(LTCABRTotalMonthlyField)?.disable();
    const LTCEBRBaseTotalMonthlyField = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.ltcEBRBaseTotalMonthly);
    this.pageQuestionForm.get(LTCEBRBaseTotalMonthlyField)?.disable();
    const totalAnnualRiderChargesField = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.totalAnnualRiderCharges);
    this.pageQuestionForm.get(totalAnnualRiderChargesField)?.disable();

    const BenefitTransferRiderField = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.benefitTransferRider);
    this.pageQuestionForm.get(BenefitTransferRiderField)?.setValue(true);
    this.pageQuestionForm.get(BenefitTransferRiderField)?.disable();
    const FlexibleCareCashAmendmentField = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.flexibleCareCashAmendment);
    this.pageQuestionForm.get(FlexibleCareCashAmendmentField)?.setValue(true);
    this.pageQuestionForm.get(FlexibleCareCashAmendmentField)?.disable();
    const LivingWellEndorsementField = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.livingWellEndorsement);
    this.pageQuestionForm.get(LivingWellEndorsementField)?.setValue(true);
    this.pageQuestionForm.get(LivingWellEndorsementField)?.disable();
    const TerminalIllnessRiderField = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.terminalIllnessRider);
    this.pageQuestionForm.get(TerminalIllnessRiderField)?.setValue(true);
    this.pageQuestionForm.get(TerminalIllnessRiderField)?.disable();

    this.setTotalLABRCharge();
    this.setTotalLEBRCharge();
  }

  setvalueProtectionEndorsementType(): void {
    this.questionsData?.pages[0]?.questions?.forEach(ques => {
      if (ques.xmlTag === policyInfoConstant.valueProtectionEndorsementType) {
        ques.questionOption?.forEach(opt => {
          if (opt.value?.toLowerCase()?.indexOf('basic') > -1 &&
            (!this.pageQuestionForm.get(ques.fieldId)?.value || this.pageQuestionForm.get(ques.fieldId)?.value?.indexOf('basic') > -1)) {
            this.pageQuestionForm.get(ques.fieldId)?.setValue(opt.value);
          }
        });
      }
    });
  }

  setLTCExtensionBenefitsRider(): void {
    const LTCExtensionBenefitsRiderFieldId = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.ltcExtensionBenefitsRider);
    if (this.pageQuestionForm.get(LTCExtensionBenefitsRiderFieldId)?.value === true) {
      const childData = this.pageQuestionData.filter((data) => data.fieldId === LTCExtensionBenefitsRiderFieldId);
      setTimeout(() => {
        this.pageQuestionForm.get(childData[0].reflexiveQuestionAL[0].fieldId)?.setValue('2years');
        this.pageQuestionForm.get(childData[0].reflexiveQuestionAL[0].fieldId)?.disable();
      }, 100);
    }
    setTimeout(() => {
      this.setTotalLABRCharge();
      this.setTotalLEBRCharge();
    }, 200);
  }

  setLTCBRInflationProtectionValue(): void {
    const marketName = this.productService.getMarketName();
    const LTCBenefitsRiderDurationFieldId = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.ltcBenefitsRiderDuration);
    const LTCBRInflationProtectionFieldId = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.ltcBRInflationProtection);
    this.filterDropDownOptionsLTCBR(marketName, LTCBRInflationProtectionFieldId);
    if (marketName === Products.MGFA) {
      this.setLTCBRInflationProtectionValueForMGFA(LTCBenefitsRiderDurationFieldId, LTCBRInflationProtectionFieldId);
    }
    if (marketName !== Products.MGMA && marketName !== Products.MGMA22 && marketName !== Products.MGMA24 && marketName !== Products.MGII) {
      const dropdownOptionsLTCBRRider = this.alldropDownOptions[LTCBenefitsRiderDurationFieldId];
      for (const val of dropdownOptionsLTCBRRider) {
        const index =  val.label.indexOf('3 Years Fixed');
        if (index > -1) {
          dropdownOptionsLTCBRRider.splice(dropdownOptionsLTCBRRider?.length - 1, 1);
        }
      }
    }
  }

  filterDropDownOptionsLTCBR(marketName: string, LTCBRInflationProtectionFieldId: string): void {
    const dropdownOptions = this.alldropDownOptions[LTCBRInflationProtectionFieldId];
    const copyofOptions = JSON.parse(JSON.stringify(dropdownOptions));
    for (const val of dropdownOptions) {
      if (marketName === Products.MGII) {
        const index =  val.label.indexOf('Market Driven');
        if (index > -1) {
          copyofOptions.splice(1, 1);
        }
      }
      if (marketName === Products.MGMA || marketName === Products.MGMA22 || marketName === Products.MGMA24) {
        const index =  val.value.indexOf('No');
        if (index > -1) {
          copyofOptions.splice(0, 1);
        }
        const index1 = val.label.indexOf('3% Compound');
        if (index1 > -1) {
          copyofOptions.splice(1, 1);
        }
      }
    }
    const foundValue = copyofOptions.filter(obj => obj.value == this.pageQuestionForm.get(LTCBRInflationProtectionFieldId)?.value?.value);
    if (foundValue.length == 0) {
      this.pageQuestionForm.get(LTCBRInflationProtectionFieldId)?.setValue('');
    }
    this.alldropDownOptions[LTCBRInflationProtectionFieldId] = copyofOptions;
  }

  setLTCBRInflationProtectionValueForMGFA(LTCBenefitsRiderDurationFieldId, LTCBRInflationProtectionFieldId): void {
    const LTCBenefitsRiderDurationValue = this.pageQuestionForm.get(LTCBenefitsRiderDurationFieldId)?.value;
    console.log(LTCBenefitsRiderDurationValue)
    if (LTCBenefitsRiderDurationValue?.label?.indexOf('No Inflation') > -1) {
      this.pageQuestionForm.get(LTCBRInflationProtectionFieldId)?.setValue({label: 'No Inflation', value: 'No{47}No'});
      this.pageQuestionForm.get(LTCBRInflationProtectionFieldId)?.disable();
      this.clipboardService.set('LTCBRInflationProtection', this.pageQuestionForm.get(LTCBRInflationProtectionFieldId).value);
      return;
    }
    if (LTCBenefitsRiderDurationValue?.label?.indexOf('3% Compound') > -1) {
      this.pageQuestionForm.get(LTCBRInflationProtectionFieldId)?.setValue({label: '3% Compound', value: '3{47}3'});
      this.pageQuestionForm.get(LTCBRInflationProtectionFieldId)?.disable();
      return;
    }
    if (LTCBenefitsRiderDurationValue?.label?.indexOf('5% Compound') > -1) {
      this.pageQuestionForm.get(LTCBRInflationProtectionFieldId)?.setValue({label: '5% Compound', value: '5{47}5'});
      this.pageQuestionForm.get(LTCBRInflationProtectionFieldId)?.disable();
      return;
    }
    if (LTCBenefitsRiderDurationValue?.label?.indexOf('3 Years Fixed') > -1) {
      this.pageQuestionForm.get(LTCBenefitsRiderDurationFieldId)?.setValue('');
    }
    this.pageQuestionForm.get(LTCBRInflationProtectionFieldId)?.setValue('');
    this.pageQuestionForm.get(LTCBRInflationProtectionFieldId).enable();
  }

  private buildFormData(): any {
    this.pageQuestionData = this.questionsData.pages[0].questions;
    this.alldropDownOptions = this.formgeneratorService.getdropdownOptions(
      this.pageQuestionData
    );
    const form = this.formgeneratorService.createFormControls(
      this.pageQuestionData
    );
    this.pageQuestionForm = this.fb.group(form);
    this.pageQuestionData.forEach(ques => {
      if (ques.xmlTag === ltcSuitabilityConstants.financialSituation) {
        setTimeout(() => {
          console.log(this.pageQuestionForm.get(ques.fieldId));
          if (ques.question_answer && ques.question_answer === 'yes') {
            this.pageQuestionForm.get(ques.fieldId)?.setValue(true);
          } else {
            console.log(this.pageQuestionForm.get(ques.fieldId));
            this.pageQuestionForm.get(ques.fieldId)?.setValue(false);
          }
        }, 200)
      }
    })
    this.valueChanges();
    setTimeout(() => {
      this.makeFieldsNonEditable();
    }, 100);
  }

  private makeFieldsNonEditable(): any {
    const fieldsToBeNonEditable =
      this.appConfig.primaryInsuredInfoDisableFieldKey;
    for (const key of fieldsToBeNonEditable) {
      const formKey = this.utilsService.getFieldId(
        this.pageQuestionData,
        key
      );
      if (formKey && this.questionsData.page_group_name === PAGE_GROUP_NAME.PRIMARY_INSURED_PAGE) {
        this.pageQuestionForm.get(formKey)?.disable();
      }
    }
  }

  displayIdCreation(data: any, isQuesTxt = false): any {
    return this.commonService.displayIdCreation(data, isQuesTxt);
  }

  private valueChanges(): any {
    let setTime = setTimeout(() => {
      this.checkFormStatus();
    }, 200);
    this.pageQuestionForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.checkFormStatus();
      }, 200);
    });
  }

  displayTextLabel(data: any): any {
    return this.commonService.displayTextLabel(data);
  }

  private checkFormStatus(): any {
    this.formValid = true;
    this.formHasNoErrors = true;
    let componentData = {
      formValid: this.formValid,
      formHasNoErrors: this.formHasNoErrors,
      form: this.pageQuestionForm,
      data: null,
    };
    for (const data of this.pageQuestionData) {
      componentData.data = data;
      componentData =
        this.formgeneratorService.updateAnswersForAllLoops(componentData);
      this.formValid = componentData.formValid;
      this.formHasNoErrors = componentData.formHasNoErrors;
    }
    this.questionsData?.pages[0]?.questions?.forEach(ques => {
      if (ques.xmlTag === ltcSuitabilityConstants.financialSituation) {
        if (ques.question_answer) {
          ques.question_answer = 'yes';
        } else {
          ques.question_answer = 'no';
        }
      }
    });
    const isAnyAlertExists = this.isAlertExists();
    const obj = {
      formValid: this.formValid && !isAnyAlertExists,
      formHasNoErrors: this.formHasNoErrors,
      questions: this.questions,
      formId: 'pageQuestionForm',
      isFormChange: this.pageQuestionForm.dirty || !this.isTabVisited,
      noGreenCardholder: this.noGreenCardholder
    };
    this.formStatus.emit(obj);
    console.log(this.pageQuestionForm)
  }

  onRadioChange(data): any {
    if (data.xmlTag === PrimaryInsuredConstant.greenCardInd) {
      const greenCardHolderField = this.getFieldIdByXmlTag(data.xmlTag);
      const greenCardHolderValue = this.pageQuestionForm.get(greenCardHolderField) && this.pageQuestionForm.get(greenCardHolderField).value;
      this.noGreenCardholder =  greenCardHolderValue?.toLowerCase()?.indexOf('no') > -1;
    }
    if (data.xmlTag === PrimaryInsuredConstant.USCitizenInd) {
      const USCitizenIndField = this.getFieldIdByXmlTag(data.xmlTag);
      const USCitizenIndValue = this.pageQuestionForm.get(USCitizenIndField) && this.pageQuestionForm.get(USCitizenIndField).value;
      this.noGreenCardholder =  USCitizenIndValue?.toLowerCase()?.indexOf('yes') > -1 ?  false : this.noGreenCardholder;
    }
    if (data.xmlTag === AgentReportKeys.doYouAttestInd) {
      this.agentReportIAttestCheck(data);
    }
    if (data.fieldId === this.marriedCivilUnionKey) {
      const marriedCivilUnionValue = this.pageQuestionForm.get(data.fieldId) && this.pageQuestionForm.get(data.fieldId).value;
      const ans = marriedCivilUnionValue?.toLowerCase()?.indexOf('yes') > -1;
      this.clipboardService.set('marriedCivilUnion',ans);
      this.emitForWetSignatureCheck();
    }
    if (data.xmlTag === KeysForIllustrationConditionals.protectionAgainstUnIntendedLapse) {
      this.emitForWetSignatureCheck();
    }
    if (data.xmlTag === ltcSuitabilityConstants.inflationProtectionInd) {
      this.inflationProtectionCheck(data.fieldId);
    }
    this.updatedAnswersOnHidden(data);
  }

  agentReportIAttestCheck(data): void {
    const doYouAttestIndFieldId = this.utilsService.getFieldId(this.pageQuestionData, data.xmlTag);
    let doYouAttestIndFieldIdValue = this.pageQuestionForm.get(doYouAttestIndFieldId)?.value || '';
    this.doYouAttestIndFieldIdValue = doYouAttestIndFieldIdValue?.split('{')[0].toLowerCase();
    if (this.doYouAttestIndFieldIdValue === 'i attest') {
      const casemanagerEmailObj = this.pageQuestionData.filter(data => data.xmlTag === this.agentReportKeys.caseManagerEmailNotAllState);
      this.caseManagerEmail = this.pageQuestionForm.get(casemanagerEmailObj[0].fieldId)?.value || '';
      this.primaryAgentEmail = this.caseManagerEmail;
      this.clipboardService.set('caseManagerEmail', this.caseManagerEmail);
      this.clipboardService.set('primaryAgentEmail', this.caseManagerEmail);
      this.hideManagerDetails();
    }
  }

  inflationProtectionCheck(fieldId): void {
    const policyInflationProtectionOption = this.userService.getPolicyInflationProtectionOption();
    if ((this.pageQuestionForm.get(fieldId).value?.toLowerCase()?.indexOf('yes') > -1
      && (policyInflationProtectionOption?.toLowerCase()?.indexOf('market') > -1 || policyInflationProtectionOption?.toLowerCase()?.indexOf('no') > -1))
      || (this.pageQuestionForm.get(fieldId).value?.toLowerCase()?.indexOf('no') > -1
              && (policyInflationProtectionOption?.toLowerCase()?.indexOf('3') > -1 || policyInflationProtectionOption?.toLowerCase()?.indexOf('5') > -1) )) {
      this.inflationProtectionMsg = true;
    } else {
      this.inflationProtectionMsg = false;
    }
  }

  redirectToPolicyInfo(): void {
    const tabConfig = this.tabDetailsService.getTabInfoByTabName('Policy Information')
    this.tabDetailsService.selectTab(tabConfig);
  }

  hideManagerDetails(): void {
    const managerFirstNameFieldId = this.utilsService.getFieldIdForAll(this.pageQuestionData, AgentReportKeys.caseManagerFirstName);
    const managerLastNameFieldId = this.utilsService.getFieldIdForAll(this.pageQuestionData, AgentReportKeys.caseManagerLastName);
    const managerPhoneNumberFieldId = this.utilsService.getFieldIdForAll(this.pageQuestionData, AgentReportKeys.caseManagerPhoneNumber);
    this.pageQuestionForm.get(managerFirstNameFieldId)?.setValue('');
    this.pageQuestionForm.get(managerLastNameFieldId)?.setValue('');
    this.pageQuestionForm.get(managerPhoneNumberFieldId)?.setValue('');
    this.pageQuestionForm.get(managerFirstNameFieldId)?.setErrors(null);
    this.pageQuestionForm.get(managerLastNameFieldId)?.setErrors(null);
    this.pageQuestionForm.get(managerPhoneNumberFieldId)?.setErrors(null);
    this.pageQuestionForm.get(managerFirstNameFieldId).markAsUntouched();
    this.pageQuestionForm.get(managerLastNameFieldId).markAsUntouched();
    this.pageQuestionForm.get(managerPhoneNumberFieldId).markAsUntouched();
  }

  handleSelectionChange(data, ix?): any {
    const formotivId = this.displayIdCreation(data);
    this.commonService.triggerChangeCustom(
      'dropdown',
      ix,
      formotivId,
      'insuredInfoForm',
      data
    );
    this.updatedAnswersOnHidden(data);
    if (data.xmlTag === policyInfoConstant.ltcBenefitsRiderDuration) {
      this.setLTCBRInflationProtectionValue();
    }
    setTimeout(() => {
      this.setTotalLABRCharge();
      this.setTotalLEBRCharge();
    }, 200);
    if (data.xmlTag === policyInfoConstant.ltcBRInflationProtection) {
      this.clipboardService.set('LTCBRInflationProtection', this.pageQuestionForm.get(data.fieldId).value);
    }
    if (data.xmlTag === TemporaryLifeInsuranceConstants.accountHolder) {
      this.clearTIAEFT();
      this.populateTIAEFTDetails(data);
    }
  }

  addCorrectPlaceHolder(data, type): any {
    this.utilsService.updatePolicyRelatedData(data);
    return this.commonService.addCorrectPlaceHolder(data, type);
  }

  updatedAnswersOnHidden(data): any {
    const value = this.pageQuestionForm.getRawValue();
    const updatedval = this.formgeneratorService.clearAnswersIfHidden(
      data,
      value
    );
    if (updatedval) {
      for (const key of Object.keys(updatedval)) {
        this.pageQuestionForm.get(key)?.markAsUntouched();
      }
      this.pageQuestionForm.patchValue(updatedval);
    }
  }

  checkIfQuestionDisplayed(data): any {
    const answer = this.pageQuestionForm.get(data.fieldId).value;
    return this.formgeneratorService.nestedQuestionsDisplayed(data, answer);
  }

  nestedQuestionCheckPageQuestion(data, childData, parent): any {
    if (data?.controlTypeDesc === this.appConfig.fieldTypes.SELECT) {
      return parent && parent.value === childData?.optionChoice;
    } else if (data?.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
      if (childData?.optionChoice?.toLowerCase().indexOf('yes') > -1) {
        return parent === true;
      } else if (childData?.optionChoice?.toLowerCase().indexOf('no') > -1) {
        return parent === false;
      }
    } else {
      return parent === childData?.optionChoice;
    }
  }

  getErrorMsg(data, formControl?: FormControl): any {
    return this.utilsService.getInlineErrorMessage(data, formControl);
  }

  updateFormData(): void {
    let gender: any = this.userService.getInsuredGender()?.split('{')[0];
    gender = gender?.indexOf('Female') > -1
      ? { value: '2{47}Female', label: 'Female' }
      : { value: '1{47}Male', label: 'Male' };

    this.pageQuestionForm.patchValue({
      [this.getFieldIdByXmlTag(PrimaryInsuredConstant.firstNameId)]: this.utilsService.toTitleCase(this.userService.getInsuredFirstName()),
      [this.getFieldIdByXmlTag(PrimaryInsuredConstant.middleNameId)]: this.utilsService.toTitleCase(this.userService.getInsuredMiddleName()),
      [this.getFieldIdByXmlTag(PrimaryInsuredConstant.lastNameId)]: this.utilsService.toTitleCase(this.userService.getInsuredLastName()),
      [this.getFieldIdByXmlTag(PrimaryInsuredConstant.suffixId)]: this.utilsService.toTitleCase(this.userService.getInsuredSuffix()),
      [this.getFieldIdByXmlTag(PrimaryInsuredConstant.dobQuestionId)]: this.utilsService.formatDateToMMDDYYYY(this.userService.getInsuredDOB()),
      [this.getFieldIdByXmlTag(PrimaryInsuredConstant.ageQuestId)]: this.userService.getInsuredAge(),
      [this.getFieldIdByXmlTag(PrimaryInsuredConstant.genderId)]: gender,
    });
  }

  private getFieldIdByXmlTag(xmlTag: string): string {
    return this.defaultPageLoadService.getFieldIdByTag(xmlTag);
  }

  getCustomAttribute() {
    const licenseNumberField = this.getFieldIdByXmlTag(PrimaryInsuredConstant.driverLicenseNum);
    const licenseNumber = this.pageQuestionForm.get(licenseNumberField) && this.pageQuestionForm.get(licenseNumberField).value;
    if (!licenseNumber) {
      return;
    }
    let patternStr = '^[A-Za-z0-9]+$';
    let textPatt = new RegExp(patternStr);
    textPatt.test(licenseNumber) ? this.pageQuestionForm.get(licenseNumberField).setErrors(null) : this.pageQuestionForm.get(licenseNumberField).setErrors({err: true});
    return 'alphaNumeric';
  }

  private isAlertExists(): boolean {
    const htmlAttAlert = Array.from(document.getElementsByTagName('lfg-alert-message'));
    let alertExist = false;
    if (htmlAttAlert?.length > 0) {
      for (const htmlElement of htmlAttAlert) {
        if (htmlElement.getAttribute('type') === 'error' && (htmlElement.innerHTML?.toLowerCase().indexOf('temporary insurance agreement is not available') > -1 || htmlElement.innerHTML?.toLowerCase().indexOf('the agent and case manager emails can only be the same if you attest that you do not have a case manager by selecting the i attest button.') > -1 || htmlElement.innerHTML?.toLowerCase().indexOf('the allowable range for the moneyguard product is $50,000-$500,000.') > -1 || htmlElement.innerHTML?.toLowerCase().indexOf('the allowable range for the moneyguard market advantage product is $50,000-$500,000.') > -1) || htmlElement.innerHTML?.toLowerCase().indexOf("the 'will you buy inflation protection?' answer does not match the 'optional inflation protection option' selection on the")  > -1) {
          alertExist = true;
          break;
        }
      }
    }
    return alertExist;
  }

  onCheckboxChange(data): any {
    const declineDisclosureFieldId = this.utilsService.getFieldId(this.pageQuestionData, ltcSuitabilityConstants.declineDisclosureInd);
    if (data.xmlTag === ltcSuitabilityConstants.financialSituation) {
      this.finalDisclosureCheck(declineDisclosureFieldId);
    }
    if (data.xmlTag === ltcSuitabilityConstants.financialSituationDescribed) {
      const financialAttestationObj = this.pageQuestionData.filter(data => data.xmlTag === ltcSuitabilityConstants.financialSituationDescribed);
      if (this.pageQuestionForm.get(financialAttestationObj[0].fieldId).value === true) {
        this.pageQuestionForm.get(declineDisclosureFieldId)?.setValue(false);
        this.pageQuestionForm.get(declineDisclosureFieldId)?.disable();
      } else {
        this.pageQuestionForm.get(declineDisclosureFieldId)?.setValue(false);
        this.pageQuestionForm.get(declineDisclosureFieldId).enable();
      }
    }
    if (data.xmlTag === ltcSuitabilityConstants.declineDisclosureInd && this.productService.getMarketName() === Products.MGFA) {
      this.declineDisclosureCheck(data);
    }
    if (data.xmlTag === ltcSuitabilityConstants.suitabilityInd) {
      const suitabilityIndFieldId = this.utilsService.getFieldId(this.pageQuestionData, ltcSuitabilityConstants.suitabilityInd);
      if (this.pageQuestionForm.get(suitabilityIndFieldId).value === true) {
        this.suitabilityIndMsg = true;
      } else {
        this.suitabilityIndMsg = false;
      }
    }
    if (data.xmlTag === policyInfoConstant.ltcExtensionBenefitsRider) {
      this.setLTCExtensionBenefitsRider();
    }
    this.updatedAnswersOnHidden(data);
  }

  finalDisclosureCheck(declineDisclosureFieldId): void {
    const disclosureIndObj = this.pageQuestionData.filter(data => data.xmlTag === ltcSuitabilityConstants.financialSituation);
    const financialAttestationFieldId = this.utilsService.getFieldId(this.pageQuestionData, ltcSuitabilityConstants.financialSituationDescribed);
    if (this.pageQuestionForm.get(disclosureIndObj[0].fieldId).value === true) {
      this.pageQuestionForm.get(financialAttestationFieldId)?.setValue(false);
      this.pageQuestionForm.get(financialAttestationFieldId)?.disable();
      this.pageQuestionForm.get(declineDisclosureFieldId)?.setValue(false);
      this.pageQuestionForm.get(declineDisclosureFieldId).enable();
    } else {
      this.pageQuestionForm.get(financialAttestationFieldId)?.setValue(false);
      this.pageQuestionForm.get(financialAttestationFieldId).enable();
    }
  }

  declineDisclosureCheck(data): void {
    const agtAcknowledgementFieldId =  this.utilsService.getFieldId(this.pageQuestionData, ltcSuitabilityConstants.agtAcknowledgement)
    if (this.pageQuestionForm.get(data.fieldId).value === true) {
      this.declineDisclosure = true;
      this.clipboardService.set('declineDisclosure', this.declineDisclosure);
    } else {
      this.declineDisclosure = false;
      this.clipboardService.set('declineDisclosure', this.declineDisclosure);
      this.pageQuestionForm.get(agtAcknowledgementFieldId)?.setValue(false);
    }
  }

  getManagerEmail(data, form): void {
    const payload = this.createPayloadForCaseManagerEmail(form, data);
    if ((payload?.caseManagerEmail !== this.previousCaseMangerEmail || payload?.caseManagerEmail !== this.previous1CaseMangerEmail)
      && this.pageQuestionForm.get(data.fieldId).valid) {
      this.addLoader(true);
      this.defaultPageLoadService.validatCaseManagerEmail(payload).subscribe((res) => {
        if (res?.data) {
          this.primaryAgentEmail = '';
        } else if (res.error) {
          this.primaryAgentEmail = form.get(data.fieldId).value;
          this.clipboardService.set('primaryAgentEmail', this.primaryAgentEmail);
        }
        this.previous1CaseMangerEmail = this.previousCaseMangerEmail;
        this.previousCaseMangerEmail = payload?.caseManagerEmail;
        this.clipboardService.set('previousCaseMangerEmail', this.previousCaseMangerEmail);
        this.clipboardService.set('previous1CaseMangerEmail', this.previous1CaseMangerEmail);
      }, (_error) => {
        console.log('error');
      }).add(() => {
        this.addLoader(false);
      });
    }
    if (data.xmlTag === AgentReportKeys.caseManagerEmailNotAllState || data.xmlTag === AgentReportKeys.caseManagerEmailAllState) {
      this.caseManagerEmail = form.get(data.fieldId).value;
      this.clipboardService.set('caseManagerEmail', this.caseManagerEmail);
      if (this.primaryAgentEmail !== this.caseManagerEmail) {
        this.doYouAttestIndFieldIdValue = '';
        this.clipboardService.set('doYouAttestIndFieldIdValue', this.doYouAttestIndFieldIdValue);
        setTimeout(() => {
          const fieldId = this.utilsService.getFieldId(this.pageQuestionData, AgentReportKeys.doYouAttestInd);
          this.pageQuestionForm.get(fieldId)?.setValue('');
          this.pageQuestionForm.get(fieldId).setErrors(null);
          this.pageQuestionForm.get(fieldId).markAsUntouched();
        }, 200)
      }
    }
  }

  private createPayloadForCaseManagerEmail(form, data): any {
    const caseManagerEmail = form.get(data.fieldId).value;
    return {
      caseId: this.pageLoadService.getCaseId(),
      caseManagerEmail,
    };
  }

  checkForZeroValue(data): void {
    this.zeroCheckFlag = false;
    this.clipboardService.set('zeroCheckFlag', this.zeroCheckFlag);
    const value =  this.pageQuestionForm.get(data.fieldId).value;
    this.zeroCheckObj[data.fieldId] = value;
    this.clipboardService.set('zeroCheckObj', this.zeroCheckObj);
    this.zeroCheckFlag = Object.values(this.zeroCheckObj).indexOf('0') > -1;
    this.clipboardService.set('zeroCheckFlag', this.zeroCheckFlag);
  }

  checkProtectionOption(data): boolean {
    const LTCBRInflationProtectionFieldId = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.ltcBRInflationProtection);
    const LTCBRInflationProtectionValue = this.pageQuestionForm.get(LTCBRInflationProtectionFieldId).value;
    if (LTCBRInflationProtectionValue?.value === '3{47}3' || LTCBRInflationProtectionValue?.value === '5{47}5') {
      return true;
    } else if (LTCBRInflationProtectionValue?.value !== undefined &&  this.pageQuestionForm.get(data?.fieldId)?.value !== '') {
      this.pageQuestionForm.get(data?.fieldId)?.setValue('');
      this.pageQuestionForm.get(data?.fieldId)?.markAsUntouched();
      this.pageQuestionForm.get(data?.fieldId)?.setErrors(null);
    }
  }

  setTotalLABRCharge(): void {
    const LTCABRTotalMonthlyField = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.ltcABRTotalMonthly);
    const LTCABRBaseMonthlyRiderField = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.ltcABRBaseMonthlyRider);
    const LTCABRBaseMonthlyInflationField = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.ltcABRBaseMonthlyInflation);
    let LTCABRTotalMonthlyFieldValue: any = +this.pageQuestionForm.get(LTCABRBaseMonthlyRiderField)?.value + +this.pageQuestionForm.get(LTCABRBaseMonthlyInflationField)?.value;
    LTCABRTotalMonthlyFieldValue = formatCurrency(LTCABRTotalMonthlyFieldValue, 'en-US', '$')
    this.pageQuestionForm.get(LTCABRTotalMonthlyField)?.setValue(LTCABRTotalMonthlyFieldValue);
    this.setTotalAnnualRiderCharges();
  }

  setTotalLEBRCharge(): void {
    const LTCEBRBaseTotalMonthlyField = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.ltcEBRBaseTotalMonthly);
    const LTCEBRBaseMonthlyRiderField = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.ltcEBRBaseMonthlyRider);
    const LTCEBRBaseMonthlyInflationField = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.ltcEBRBaseMonthlyInflation);
    let LTCEBRBaseTotalMonthlyFieldValue: any = +this.pageQuestionForm.get(LTCEBRBaseMonthlyRiderField)?.value + +this.pageQuestionForm.get(LTCEBRBaseMonthlyInflationField)?.value;
    LTCEBRBaseTotalMonthlyFieldValue = formatCurrency(LTCEBRBaseTotalMonthlyFieldValue, 'en-US', '$')
    this.pageQuestionForm.get(LTCEBRBaseTotalMonthlyField)?.setValue(LTCEBRBaseTotalMonthlyFieldValue);
    this.setTotalAnnualRiderCharges();
  }

  setTotalAnnualRiderCharges(): void {
    const totalAnnualRiderChargesField = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.totalAnnualRiderCharges);
    const LTCABRTotalMonthlyField = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.ltcABRTotalMonthly);
    const LTCEBRBaseTotalMonthlyField = this.utilsService.getFieldId(this.pageQuestionData, policyInfoConstant.ltcEBRBaseTotalMonthly);
    let totalAnnualRiderCharges: any = (Number(this.pageQuestionForm.get(LTCABRTotalMonthlyField)?.value?.replace(/[^0-9.]+/g, "")) + Number(this.pageQuestionForm.get(LTCEBRBaseTotalMonthlyField)?.value?.replace(/[^0-9.]+/g, "")))*12;
    totalAnnualRiderCharges = formatCurrency(totalAnnualRiderCharges, 'en-US', '$')
    this.pageQuestionForm.get(totalAnnualRiderChargesField)?.setValue(totalAnnualRiderCharges);
  }

  populateTIAEFTDetails(data: any): void {
    const ownerDetails: any = this.userService.getOwnerDetailsWithAddress();
    const accountHolderName = this.pageQuestionForm.get(data?.fieldId)?.value?.label;
    let selectedOwnerDetails: any = null;
    ownerDetails?.forEach(element => {
      const fullName = element.firstName + ' ' + element.lastName;
      if (accountHolderName?.toLowerCase()?.indexOf(fullName?.toLowerCase()) > -1
        || accountHolderName?.toLowerCase()?.indexOf(element.trustName?.toLowerCase()) > -1
        || accountHolderName?.toLowerCase()?.indexOf(element.entityName?.toLowerCase()) > -1) {
        selectedOwnerDetails = element;
      }
    });
    this.setTIAEFTFieldValues(selectedOwnerDetails, accountHolderName);
  }

  clearTIAEFT(): void {
    for (const key in EFTKeys) {
      const fieldId = this.utilsService.getFieldId(this.pageQuestionData, EFTKeys[key]);
      this.pageQuestionForm?.get(fieldId)?.setValue('');
    }
  }

  setTIAEFTFieldValues(selectedOwnerDetails: any, accountHolderName): void {
    const maintFieldValues = true;
    for (const key in EFTKeys) {
      const fieldId = this.utilsService.getFieldId(this.pageQuestionData, EFTKeys[key]);
      if (key === 'streetAddress2' || key === 'streetAddress3') {
        continue;
      }
      if (selectedOwnerDetails) {
        this.setFormValueforTIA(key, selectedOwnerDetails, fieldId);
      } else if (accountHolderName?.toLowerCase()?.indexOf('other') > -1 && maintFieldValues) {
        const val = this.pageQuestionForm?.get(fieldId)?.value;
        if (val && val?.length === 0) {
          this.pageQuestionForm?.get(fieldId)?.setValue('');
        }
      } else {
        this.pageQuestionForm?.get(fieldId)?.setValue('');
      }
    }
  }

  setFormValueforTIA(key, selectedOwnerDetails, fieldId): void {
    let valueToPopulate;
    if (key === 'state') {
      const dropdownVal = selectedOwnerDetails?.address[key]?.split('{')[0];
      valueToPopulate = dropdownVal?.length > 0 ? { value: dropdownVal, label: dropdownVal } : '';
    } else if (key === 'streetAddress') {
      valueToPopulate = `${selectedOwnerDetails?.address[key] || ''} ${selectedOwnerDetails?.address?.streetAddress2 || ''} ${selectedOwnerDetails?.address?.streetAddress3 || ''}`.trim();
    } else {
      valueToPopulate = (key === 'city' || key === 'zip' ) ? (selectedOwnerDetails?.address[key] || '') : (selectedOwnerDetails[key] || '');
    }
    valueToPopulate = _.isEmpty(valueToPopulate) ? '' : valueToPopulate;
    this.pageQuestionForm.get(fieldId)?.setValue(valueToPopulate);
  }

  private emitForWetSignatureCheck(): void {
    if (this.questionsData.page_group_name === PAGE_GROUP_NAME.PRIMARY_INSURED_PAGE) {
      const isMarried = this.pageQuestionForm.get(this.marriedCivilUnionKey)?.value?.toLowerCase()?.indexOf('yes') > -1;
      this.userService.setIsMarried(isMarried);
      this.checkForWetSignature.emit(isMarried ? 'yes' : 'no');
    } else if (this.questionsData.page_group_name === PAGE_GROUP_NAME.THIRD_PARTY_DESIGNEE_PAGE) {
      const protectionAgainstUnIntendedLapse = this.pageQuestionForm.get(this.getFieldIdByXmlTag(KeysForIllustrationConditionals.protectionAgainstUnIntendedLapse))?.value?.toLowerCase()?.indexOf('yes') > -1;
      this.userService.setIsUnIntendedLapse(protectionAgainstUnIntendedLapse);
      this.checkForWetSignature.emit(protectionAgainstUnIntendedLapse ? 'yes' : 'no');
    }
  }

  addLoader(isAddLoader: boolean): void {
    if (isAddLoader) {
      this.showLoader = true;
      this.loaderService.show();
    } else {
      setTimeout(() => {
        this.loaderService.hide();
        this.showLoader = false;
        this.changeDetectorRef.detectChanges();
      }, 0);
    }
  }
}
