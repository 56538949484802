<div class="questions--container">
	<form [formGroup]="illustrationForm" method="post" class="form-group">

		<section class="border-bottom padbot20" *ngIf="product===products.MGFA || product===products.MGII">
			<div class="questions-header">
				<h2>Policy Information</h2>
			</div>

            <p>SOLVE FOR:</p>
            <div class="lfg-radio"
				[ngClass]="{'radio--err': (illustrationForm.get('solveFor').touched && (illustrationForm.get('solveFor').invalid))}">
				<div class="form--control form--control__full-width-inline">
					<input tabindex="0" id="radio_premium_face_amt" type="radio" formControlName="solveFor" name="solveFor"
						value="premiumFaceAmt" required="true" (change)="onRadioChange($event)" />
					<label for="radio_premium_face_amt" class="with-inline">
                        <span>Premium with face amount of</span>
                        <span class="inline-input-wrap">
							<input class="inline-input" id="face_amt" type="text" placeholder="$0"
								appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols
								customAttr="numbersOnly" maxlength='9' formControlName="faceAmt">
						</span>
                    </label>
                    <lfg-error *ngIf="illustrationForm.get('faceAmt').touched && illustrationForm.get('faceAmt').invalid"
					        message="Allowable modal premium range $200.00 - $999,999,999.00 (Face amount entered results in a modal premium of $0.00)"></lfg-error>
				</div>
				<div class="form--control form--control__full-width-inline">
					<input tabindex="0" id="radio_premium_benefit" type="radio" formControlName="solveFor" name="solveFor"
						value="premiumBenefit" required="true" (change)="onRadioChange($event)">
                    <label class="wrap wrap-check with-inline" for="radio_premium_benefit">
                        <span class="text">Premium with a monthly benefit amount of</span>
                        <span class="inline-input-wrap text-input">
                            <input class=" inline-input text-input" id="benefit_amt" type="text" placeholder="0"
                                formControlName="benefitAmt" appCurrencyFormat allowDecimals="false" appValidateOnBlur
                                appNoSpecialSymbols customAttr="numbersOnly" maxlength='9'>
                        </span>
                        <span class="text">at</span>
                        <span class="inline-input-wrap dropdown">
                            <div class="inline-input form-dropDown">
                                <lfg-dropdown formControlName="benefitTimeDropdown" id="benefit_time_dropdown" [options]="benefitTimeOptions"
                                    (click)="false" placeholder=""
                                    (selectionChanged)="handleSelectionChange('benefitTimeDropdown')" [required]="true"
                                    [ngClass]="{'dropdown--err': illustrationForm.get('benefitTimeDropdown').touched && illustrationForm.get('benefitTimeDropdown').invalid}">
                                </lfg-dropdown>
                            </div>
                        </span>
                        <span class="inline-input-wrap dropdown">
                            <div class="inline-input form-dropDown">
                                <lfg-dropdown formControlName="benefitTimeVal" id="benefit_time_val" [options]="benefitTimeValOptions"
                                    (click)="false" placeholder=""
                                    (selectionChanged)="handleSelectionChange('benefitTimeVal', 0)" [required]="true"
                                    [ngClass]="{'dropdown--err': illustrationForm.get('benefitTimeVal').touched && illustrationForm.get('benefitTimeVal').invalid}">
                                </lfg-dropdown>
                            </div>
                        </span>
                    </label>
                    <lfg-error *ngIf="illustrationForm.get('benefitAmt').touched && illustrationForm.get('benefitAmt').invalid"
					        message="Allowable modal premium range $200.00 - $999,999,999.00 (Monthly benefit amount entered results in a modal premium of $0.00)"></lfg-error>
				</div>
                <div class="section-divider"></div>

                <div class="form--control form--control__full-width-inline">
                    <input tabindex="0" id="radio_face_amt" type="radio" formControlName="solveFor" name="solveFor"
                            value="faceAmt" required="true" (change)="onRadioChange($event)">
                    <label for="radio_face_amt" class="with-inline wrap wrap-check">
                        <span class="text">Face amount with premium of</span>
                        <span class="inline-input-wrap">
                            <input class="inline-input" id="face_amt" type="text" placeholder="$0"
                                appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols
                                customAttr="numbersOnly" maxlength='9' formControlName="premium">
                        </span>
                    </label>
                    <lfg-error *ngIf="illustrationForm.get('premium').touched && illustrationForm.get('premium').invalid"
					        message="Allowable modal premium range $200.00 - $999,999,999.00"></lfg-error>
                </div>

				<lfg-error *ngIf="illustrationForm.get('solveFor').touched && illustrationForm.get('solveFor').invalid"
					message="Please select a valid option"></lfg-error>
			</div>

            <div class="form--control form--control__full-width">
                <div class="with-inline">
                    <span>Payment option</span>
                    <span class="inline-input-wrap">
                        <input class="inline-input" id="pmt_option" type="text" placeholder="0"
                            allowDecimals="false" appValidateOnBlur appNoSpecialSymbols
                            customAttr="numbersOnly" maxlength='3' formControlName="pmtOption">
                    </span>
                    <span>year(s)</span>
                </div>
                <lfg-error *ngIf="illustrationForm.get('pmtOption').touched && illustrationForm.get('pmtOption').invalid"
					        message="Zero premium only allowed with Single Pay payment option"></lfg-error>
			</div>
            <a href="https://www.lfg.com/wcs-static/pdf/MGIIFlexPayChartCoverPage.pdf" target="_blank" rel="noopener">Payment option information</a>

            <div class="section-divider"></div>

            <div class="form--control form--control__full-width">
                <div class="with-inline">
                    <span class="text">Premium mode</span>
                    <span class="inline-input-wrap wide-dropdown">
                        <div class="inline-input form-dropDown">
                            <lfg-dropdown formControlName="premiumMode" id="premium_mode" [options]="premiumModeOptions"
                                (click)="false" placeholder=""
                                (selectionChanged)="handleSelectionChange('premiumMode', 0)" [required]="true"
                                [ngClass]="{'dropdown--err': illustrationForm.get('premiumMode').touched && illustrationForm.get('premiumMode').invalid}">
                            </lfg-dropdown>
                        </div>
                    </span>
                    <span class="text inline-pad">Lump sum</span>
                    <span class="inline-input-wrap text-input">
                        <input class=" inline-input text-input" id="lump_sum" type="text" placeholder="0"
                            formControlName="lumpSum" appCurrencyFormat allowDecimals="false" appValidateOnBlur
                            appNoSpecialSymbols customAttr="numbersOnly" maxlength='9'>
                    </span>
                </div>
            </div>


            <div class="form--control form--control__full-width">
				<div class="lfg-checkbox">
					<input id="allow_exchange" type="checkbox" value="checked" name="allow_exchange"
						formControlName='allowExchangeToFund' [required]="false"
						(change)="onCheckboxChange('allowExchangeToFund'); checkIfReasonSelectedErrorExists()">
					<label class="wrap wrap-check" for="allow_exchange">Allow exchange and lump sum amounts to fund year one</label>
				</div>
			</div>
		</section>

		<section class="border-bottom padbot20" *ngIf="product===products.MGFA || product===products.MGII">
			<div class="questions-header">
				<h2>Exchange Information</h2>
			</div>


			<div class="form--control form--control__full-width">
				<div class="lfg-checkbox">
					<input id="illustrate_exchange" type="checkbox" value="checked" name="illustrate_exchange"
						formControlName='illustrateExchange' [required]="false"
						(change)="onCheckboxChange('illustrateExchange'); checkIfReasonSelectedErrorExists()">
					<label class="wrap wrap-check" for="illustrate_exchange">Illustrate with an exchange</label>
				</div>
			</div>

            <div class="form--control form--control__full-width">
				<div class="lfg-checkbox">
					<input id="is_MEC" type="checkbox" value="checked" name="is_MEC"
						formControlName='isMEC' [required]="false"
						(change)="onCheckboxChange('isMEC'); checkIfReasonSelectedErrorExists()">
					<label class="wrap wrap-check" for="is_MEC">Exchange is a modified endowment contract (MEC)</label>
				</div>
			</div>

            <div class="form--control form--control__full-width with-inline">
                <span class="text">Internal cash value</span>
                <span class="inline-input-wrap text-input">
                    <input class=" inline-input text-input" id="internal_cash_val" type="text" placeholder="0"
                        formControlName="internalCashVal" appCurrencyFormat allowDecimals="false" appValidateOnBlur
                        appNoSpecialSymbols customAttr="numbersOnly" maxlength='9'>
                </span>
                <span class="text inline-pad">External cash value</span>
                <span class="inline-input-wrap text-input">
                    <input class=" inline-input text-input" id="external_cash_val" type="text" placeholder="0"
                        formControlName="externalCashVal" appCurrencyFormat allowDecimals="false" appValidateOnBlur
                        appNoSpecialSymbols customAttr="numbersOnly" maxlength='9'>
                </span>
            </div>
		</section>

		<section class="border-bottom padbot20" *ngIf="product===products.MGFA">
			<div class="questions-header">
				<h2>Value Protection Endorsement</h2>
			</div>

            <div class="form--control form--control__full-width">
                <div class="with-inline">
                    <span>Value Protection Endorsement type</span>
                    <span class="inline-input-wrap">
                        <div class="form-dropDown inline-input">
                            <lfg-dropdown formControlName="endorsementType" id="endorsement_type"
                                [options]="endorsementTypeOptions" placeholder=""
                                (selectionChanged)="handleSelectionChange('endorsementType', 0)" [required]="true"
                                [ngClass]="{'dropdown--err': illustrationForm.get('endorsementType').touched && illustrationForm.get('endorsementType').invalid}">
                            </lfg-dropdown>
                        </div>
                    </span>
                </div>
            </div>
		</section>

		<section class="border-bottom padbot20" *ngIf="product==products.MGFA">
			<div class="questions-header">
				<h2>Long-term care benefits rider</h2>
			</div>

            <div class="form--control form--control__full-width">
                <div class="with-inline">
                    <span>Benefit plan</span>
                    <span class="inline-input-wrap wide-dropdown">
                        <div class="form-dropDown inline-input">
                            <lfg-dropdown formControlName="benefitPlan" id="benefit_plan"
                                [options]="benefitPlanOptions" placeholder=""
                                (selectionChanged)="handleSelectionChange('benefitPlan', $event.value)" [required]="true"
                                [ngClass]="{'dropdown--err': illustrationForm.get('benefitPlan').touched && illustrationForm.get('benefitPlan').invalid}">
                            </lfg-dropdown>
                        </div>
                    </span>
                </div>
                <lfg-error *ngIf="illustrationForm.get('benefitPlan').touched && illustrationForm.get('benefitPlan').invalid"
					message="Long - Term Care Rider Benefit plan selection required"></lfg-error>
            </div>
		</section>

        <section class="border-bottom padbot20" *ngIf="product===products.MGII">
            <div class="questions-header">
                <h2>Value Protection Rider</h2>
            </div>
            <div class="form--control form--control__full-width">
                <div class="lfg-checkbox">
                    <input id="illustrate_value_protection" type="checkbox" value="checked" name="illustrate_value_protection"
                        formControlName='illustrateWithValueProtection' [required]="false"
                        (change)="onCheckboxChange('illustrateWithValueProtection'); checkIfReasonSelectedErrorExists()">
                    <label class="wrap wrap-check" for="illustrate_value_protection">Illustrate with the Value Protection Rider</label>
                </div>
            </div>
            <div class="form-control form--control__full-width">
                <div class="with-inline">
                    <span class="text">Value Protection Rider type</span>
                    <span class="inline-input-wrap dropdown">
                        <div class="inline-input form-dropDown">
                            <lfg-dropdown formControlName="valueProtectionRiderType" id="value_protection_rider_type" [options]="endorsementTypeOptions"
                                (click)="false" placeholder=""
                                (selectionChanged)="handleSelectionChange('valueProtectionRiderType', 0)" [required]="true"
                                [ngClass]="{'dropdown--err': illustrationForm.get('valueProtectionRiderType').touched && illustrationForm.get('valueProtectionRiderType').invalid}">
                            </lfg-dropdown>
                        </div>
                    </span>
                </div>
            </div>
        </section>

        <section class="border-bottom padbot20" *ngIf="product===products.MGII">
            <div class="questions-header">
                <h2>Long-Term Care Acceleration of Benefits Rider</h2>
            </div>
            <div class="form--control form--control__full-width">
                <div class="lfg-checkbox">
                    <input id="illustrate_ltc_acceleration" type="checkbox" value="checked" name="illustrate_ltc_acceleration"
                        formControlName='illustrateWithLTCAcceleration' [required]="false"
                        (change)="onCheckboxChange('illustrateWithLTCAcceleration'); checkIfReasonSelectedErrorExists()">
                    <label class="wrap wrap-check" for="illustrate_ltc_acceleration">Illustrate with the Long-Term Care Acceleration of Benefits Rider</label>
                </div>
            </div>
            <div class="form-control form--control__full-width">
                <div class="with-inline">
                    <span class="text">Benefit plan</span>
                    <span class="inline-input-wrap wide-dropdown">
                        <div class="inline-input form-dropDown">
                            <lfg-dropdown formControlName="accelerationBenefitPlan" id="value_protection_rider_type" [options]="accelerationBenefitsOptions"
                                (click)="false" placeholder=""
                                (selectionChanged)="handleSelectionChange('accelerationBenefitPlan', 0)" [required]="true"
                                [ngClass]="{'dropdown--err': illustrationForm.get('accelerationBenefitPlan').touched && illustrationForm.get('accelerationBenefitPlan').invalid}">
                            </lfg-dropdown>
                        </div>
                    </span>
                </div>
            </div>
        </section>

        <section class="border-bottom padbot20" *ngIf="product===products.MGII">
            <div class="questions-header">
                <h2>Long-Term Care Extension of Benefits Rider</h2>
            </div>
            <div class="form--control form--control__full-width">
                <div class="lfg-checkbox">
                    <input id="illustrate_ltc_extension" type="checkbox" value="checked" name="illustrate_ltc_extension"
                        formControlName='illustrateWithLTCExtension' [required]="false"
                        (change)="onCheckboxChange('illustrateWithLTCExtension'); checkIfReasonSelectedErrorExists()">
                    <label class="wrap wrap-check" for="illustrate_ltc_extension">Illustrate with the Long-Term Care Extension of Benefits Rider</label>
                </div>
            </div>
            <div class="form-control form--control__full-width">
                <div class="with-inline">
                    <span class="text">Benefit plan</span>
                    <span class="inline-input-wrap wide-dropdown">
                        <div class="inline-input form-dropDown">
                            <lfg-dropdown formControlName="extensionBenefitPlan" id="value_protection_rider_type" [options]="extensionBenefitsOptions"
                                (click)="false" placeholder=""
                                (selectionChanged)="handleSelectionChange('extensionBenefitPlan', 0)" [required]="true"
                                [ngClass]="{'dropdown--err': illustrationForm.get('extensionBenefitPlan').touched && illustrationForm.get('extensionBenefitPlan').invalid}">
                            </lfg-dropdown>
                        </div>
                    </span>
                </div>
            </div>
        </section>


		<section class="border-bottom padbot20" *ngIf="product===products.MGFA || product===products.MGII">
			<div class="questions-header">
				<h2>Backdating</h2>
			</div>

			<div class="lfg-radio"
				[ngClass]="{'radio--err': (illustrationForm.get('backdate').touched && (illustrationForm.get('backdate').invalid))}">
				<div class="form--control form--control__full-width-inline">
					<input tabindex="0" id="radio_none" type="radio" formControlName="backdate" name="backdate"
						value="none" required="true" (change)="onRadioChange($event)" />
					<label for="radio_none">Do not illustrate with backdating</label>
				</div>
				<div class="form--control form--control__full-width-inline">
					<input tabindex="0" id="radio_manual" type="radio" formControlName="backdate" name="backdate"
						value="manual" required="true" (change)="onRadioChange($event)">
					<label for="radio_manual" class="with-inline">
						<span class="text">Manual backdate using number of months</span>
						<span class="inline-input-wrap">
							<div class="form-dropDown inline-input">
                                <lfg-dropdown formControlName="backdateMonths" id="backdate_months"
                                    [options]="backdateMonthOptions" placeholder="" (click)="false"
                                    (selectionChanged)="handleSelectionChange('backdateMonths', 0)" [required]="true"
                                    [ngClass]="{'dropdown--err': illustrationForm.get('backdateMonths').touched && illustrationForm.get('backdateMonths').invalid}">
                                </lfg-dropdown>
                            </div>
						</span>
					</label>
				</div>
				<lfg-error *ngIf="illustrationForm.get('backdate').touched && illustrationForm.get('backdate').invalid"
					message="Please select a valid option"></lfg-error>
			</div>
		</section>

        <section class="border-bottom padbot20" *ngIf="product===products.MGMA22 || product===products.MGMA || product===products.MGMA24">
            <div class="questions-header">
                <h2>Face Amount and Benefits</h2>
            </div>

            <div class="form--control form--control__full-width">
                <div class="with-inline">
                    <span class="text">Face amount and benefits</span>
                    <span class="inline-input-wrap wide-dropdown">
                        <div class="inline-input form-dropDown">
                            <lfg-dropdown formControlName="faceAmtAndBenefits" id="face_amt_and_benefits" [options]="faceAmtAndBenefitsOptions"
                                (click)="false" placeholder=""
                                (selectionChanged)="handleSelectionChange('faceAmtAndBenefits', 0)" [required]="true"
                                [ngClass]="{'dropdown--err': illustrationForm.get('faceAmtAndBenefits').touched && illustrationForm.get('faceAmtAndBenefits').invalid}">
                            </lfg-dropdown>
                        </div>
                    </span>
                </div>
            </div>
        </section>

        <section class="border-bottom padbot20" *ngIf="product===products.MGMA22 || product===products.MGMA || product===products.MGMA24">
            <div class="questions-header">
                <h2>Premium</h2>
            </div>

            <div class="form--control form--control__full-width">
                <div class="with-inline">
                    <span class="text">Premium mode</span>
                    <span class="inline-input-wrap wide-dropdown">
                        <div class="inline-input form-dropDown">
                            <lfg-dropdown formControlName="premiumMode" id="benefit_time_val" [options]="premiumModeOptions"
                                (click)="false" placeholder=""
                                (selectionChanged)="handleSelectionChange('premiumMode', 0)" [required]="true"
                                [ngClass]="{'dropdown--err': illustrationForm.get('premiumMode').touched && illustrationForm.get('premiumMode').invalid}">
                            </lfg-dropdown>
                        </div>
                    </span>
                </div>
            </div>
            <div class="lfg-radio"
				[ngClass]="{'radio--err': (illustrationForm.get('modalPremium').touched && (illustrationForm.get('modalPremium').invalid))}">
				<div class="form--control form--control__full-width-inline">
					<input tabindex="0" id="radio_fixed" type="radio" formControlName="modalPremium" name="modalPremium"
						value="fixedSchedule" required="true" (change)="onRadioChange($event)">
                    <label class="wrap wrap-check with-inline" for="radio_fixed">
                        <span class="inline-input-wrap wide-dropdown">
                            <div class="inline-input form-dropDown">
                                <lfg-dropdown formControlName="modalPremiumDropdown" id="modal_premium_dropdown" [options]="modalPremiumOptions"
                                    (click)="false" placeholder=""
                                    (selectionChanged)="handleSelectionChange('modalPremiumDropdown', 0)" [required]="true"
                                    [ngClass]="{'dropdown--err': illustrationForm.get('modalPremiumDropdown').touched && illustrationForm.get('modalPremiumDropdown').invalid}">
                                </lfg-dropdown>
                            </div>
                        </span>
                        <span class="inline-input-wrap text-input" *ngIf="illustrationForm.get('modalPremiumDropdown').value.value === 'knownPremium'">
                            <input class=" inline-input text-input" id="modalPremiumAmt" type="text" placeholder="0"
                                formControlName="modalPremiumAmt" appCurrencyFormat allowDecimals="false" appValidateOnBlur
                                appNoSpecialSymbols customAttr="numbersOnly" maxlength='9'>
                        </span>
                        <span class="text">thru</span>
                        <span class="inline-input-wrap dropdown">
                            <div class="inline-input form-dropDown">
                                <lfg-dropdown formControlName="modalPremiumTime" id="modal_premium_time" [options]="timePeriodOptions"
                                    (click)="false" placeholder=""
                                    (selectionChanged)="handleSelectionChange('modalPremiumTime', $event)" [required]="true"
                                    [ngClass]="{'dropdown--err': illustrationForm.get('modalPremiumTime').touched && illustrationForm.get('modalPremiumTime').invalid}">
                                </lfg-dropdown>
                            </div>
                        </span>
                        <span class="inline-input-wrap text-input">
                            <input class=" inline-input text-input" id="modal_premium_time_amt" type="text" placeholder="0"
                                formControlName="modalPremiumTimeAmt" allowDecimals="false" appValidateOnBlur
                                appNoSpecialSymbols customAttr="numbersOnly" maxlength='3'>
                        </span>
                    </label>
				</div>
                <lfg-error *ngIf="illustrationForm.get('modalPremiumTimeAmt').touched && illustrationForm.get('modalPremiumTimeAmt').invalid && illustrationForm.get('modalPremiumTime').value.value==='year'"
				    message="Allowable range 1-26"></lfg-error>
                <lfg-error *ngIf="illustrationForm.get('modalPremiumTimeAmt').touched && illustrationForm.get('modalPremiumTimeAmt').invalid && illustrationForm.get('modalPremiumTime').value.value==='age'"
                    message="Allowable range 50-75"></lfg-error>
                <div class="form--control form--control__full-width-inline">
					<input tabindex="0" id="radio_vary" type="radio" formControlName="modalPremium" name="modalPremium"
						value="varySchedule" required="true" (change)="onRadioChange($event)" />
					<label for="radio_vary" class="with-inline">
                        <p class="modal-link" (click)="openModal('varyPremium');false"><i class="fas fa-table"></i> Vary scheduled premium</p>
                    </label>

				</div>
			</div>
        </section>

        <section class="border-bottom padbot20" *ngIf="product===products.MGMA22 || product===products.MGMA || product===products.MGMA24">
            <div class="questions-header">
                <h2>Lump Sum Information</h2>
            </div>

            <p>Illustrate with</p>
            <div class="form--control form--control__full-width">
                <div class="lfg-checkbox">
                    <input id="illustrate_w_lump_sum" type="checkbox" value="checked" name="illustrate_w_lump_sum"
                        formControlName='illustrateWithLumpSum' [required]="false"
                        (change)="onCheckboxChange('illustrateWithLumpSum'); checkIfReasonSelectedErrorExists()">
                    <label class="wrap wrap-check" for="illustrate_w_lump_sum">Lump sum payment</label>    
                </div>
                
            </div>

            <p class="modal-link"><i class="fas fa-table"></i> First year lump sum payment</p>

            <div class="form--control form--control__full-width">
				<div class="lfg-checkbox">
					<input id="allow_exchange" type="checkbox" value="checked" name="allow_exchange"
						formControlName='allowExchangeToFund' [required]="false"
						(change)="onCheckboxChange('allowExchangeToFund'); checkIfReasonSelectedErrorExists()">
					<label class="wrap wrap-check" for="allow_exchange">Allow exchange and lump sum amounts to fund year one</label>
				</div>
			</div>
        </section>

        <section class="border-bottom padbot20" *ngIf="product===products.MGMA22 || product===products.MGMA || product===products.MGMA24">
            <div class="questions-header">
                <h2>Rate of Return Assumptions</h2>
            </div>
            
            <p>Variable account rate of return</p>
            <div class="lfg-radio"
				[ngClass]="{'radio--err': (illustrationForm.get('variableRateSelection').touched && (illustrationForm.get('variableRateSelection').invalid))}">
				<div class="form--control form--control__full-width-inline">
					<input tabindex="0" id="radio_rate_fixed" type="radio" formControlName="variableRateSelection" name="variableRateSelection"
						value="fixedRate" required="true" (change)="onRadioChange($event)">
                    <label class="wrap wrap-check with-inline" for="radio_rate_fixed">
                        <span class="inline-input-wrap wide-dropdown">
                            <div class="inline-input form-dropDown">
                                <lfg-dropdown formControlName="variableRateDropdown" id="variable_rate_dropdown" [options]="rateOfReturnOptions"
                                    (click)="false" placeholder=""
                                    (selectionChanged)="handleSelectionChange('variableRateDropdown', 0)" [required]="true"
                                    [ngClass]="{'dropdown--err': illustrationForm.get('variableRateDropdown').touched && illustrationForm.get('variableRateDropdown').invalid}">
                                </lfg-dropdown>
                            </div>
                        </span>
                        <span class="inline-input-wrap text-input" *ngIf="illustrationForm.get('variableRateDropdown').value.value === 'knownAmount'">
                            <input class=" inline-input text-input" id="variable_rate" type="text" placeholder="0"
                                formControlName="variableRate" allowDecimals="false" appValidateOnBlur appPercentageFormat
                                appNoSpecialSymbols customAttr="numbersOnly" maxlength='2'>
                        </span>
                    </label>
				</div>
                <lfg-error *ngIf="illustrationForm.get('variableRate').touched && illustrationForm.get('variableRate').invalid && illustrationForm.get('variableRateDropdown').value.value === 'knownAmount'"
                        message="Allowable range 0% - 12%"></lfg-error>
                <lfg-error *ngIf="illustrationForm.get('variableRateDropdown').value.value !== 'knownAmount'" message="Only one solve allowed"></lfg-error>
                
                <div class="form--control form--control__full-width-inline">
					<input tabindex="0" id="radio_rate_vary" type="radio" formControlName="variableRateSelection" name="variableRateSelection"
						value="varyRate" required="true" (change)="onRadioChange($event)" />
					<label for="radio_rate_vary" class="with-inline">
                        <span class="modal-link"><i class="fas fa-table"></i> First year lump sum payment</span>
                    </label>
				</div>
			</div>

            <div class="form--control form--control__full-width with-inline">
                <span>Alternate rate of return (optional)</span>
                <span class="inline-input-wrap">
                    <input class="inline-input" id="alt_rate" type="text" placeholder="0%"
                        appPercentageFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols
                        customAttr="numbersOnly" maxlength='2' formControlName="alternateRate">
                </span>
			</div>
            <lfg-error *ngIf="illustrationForm.get('alternateRate').touched && illustrationForm.get('alternateRate').invalid"
                    message="Allowable range 0% - 12%"></lfg-error>


        </section>

        <section class="border-bottom padbot20" *ngIf="product===products.MGMA22 || product===products.MGMA || product===products.MGMA24">
            <div class="questions-header">
                <h2>Solve Objective</h2>
            </div>

            <div class="form--control__full-width with-inline">
                <span class="text">Solve objective</span>
                <span class="inline-input-wrap">
                    <input class="inline-input" id="alt_rate" type="text" placeholder="$0"
                        appCurrencyFormat allowDecimals="false" appValidateOnBlur appNoSpecialSymbols
                        customAttr="numbersOnly" maxlength='9' formControlName="solveObjective">
                </span>
                <span class="text">in</span>
                <span class="inline-input-wrap dropdown">
                    <div class="inline-input form-dropDown">
                        <lfg-dropdown formControlName="solveObjectiveTime" id="variable_rate_dropdown" [options]="endTimePeriodOptions"
                            (click)="false" placeholder=""
                            (selectionChanged)="handleSelectionChange('solveObjectiveTime', 0)" [required]="true"
                            [ngClass]="{'dropdown--err': illustrationForm.get('solveObjectiveTime').touched && illustrationForm.get('solveObjectiveTime').invalid}">
                        </lfg-dropdown>
                    </div>
                </span>
                <span class="inline-input-wrap text-input" *ngIf="illustrationForm.get('solveObjectiveTime').value.value != 'maturity'">
                    <input class=" inline-input text-input" id="variable_rate" type="text" placeholder="0"
                        formControlName="solveObjectiveTimeValue" allowDecimals="false" appValidateOnBlur
                        appNoSpecialSymbols customAttr="numbersOnly" maxlength='2'>
                </span>
            </div>
            <lfg-error *ngIf="illustrationForm.get('solveObjective').touched && illustrationForm.get('solveObjective').invalid"
                        message="Solve objective value must be greater than 0"></lfg-error>

        </section>

        <section class="border-bottom padbot20" *ngIf="product===products.MGMA22 || product===products.MGMA || product===products.MGMA24">
            <div class="questions-header">
                <h2>No Lapse Guarantee Period</h2>
            </div>
            <div class="form--control__full-width with-inline">
                <span class="text">Guarantee until</span>
                <span class="inline-input-wrap dropdown">
                    <div class="inline-input form-dropDown">
                        <lfg-dropdown formControlName="guaranteeUntil" id="guarantee_until" [options]="endTimePeriodOptions"
                            (click)="false" placeholder=""
                            (selectionChanged)="handleSelectionChange('guaranteeUntil', 0)" [required]="true"
                            [ngClass]="{'dropdown--err': illustrationForm.get('guaranteeUntil').touched && illustrationForm.get('guaranteeUntil').invalid}">
                        </lfg-dropdown>
                    </div>
                </span>
                <span class="inline-input-wrap text-input" *ngIf="illustrationForm.get('guaranteeUntil').value.value != 'maturity'">
                    <input class=" inline-input text-input" id="guarantee_until_time" type="text" placeholder="0"
                        formControlName="guaranteeUntilTime" allowDecimals="false" appValidateOnBlur
                        appNoSpecialSymbols customAttr="numbersOnly" maxlength='2'>
                </span>
            </div>
        </section>

        <section class="border-bottom padbot20" *ngIf="product===products.MGMA22 || product===products.MGMA || product===products.MGMA24">
            <div class="questions-header">
                <h2>Exchange Information</h2>
            </div>

            <div class="form--control form--control__full-width-inline with-inline">
                <span class="text">Illustrate with</span>
                <span class="lfg-checkbox inline-input-wrap">
                    <input id="external_exchange" type="checkbox" value="checked" name="external_exchange"
                        formControlName='externalExchange' [required]="false"
                        (change)="onCheckboxChange('externalExchange'); checkIfReasonSelectedErrorExists()">
                    <label class="wrap wrap-check" for="external_exchange">External exchange</label>
                </span>
                <span class="lfg-checkbox inline-input-wrap">
                    <input id="internal_exchange" type="checkbox" value="checked" name="internal_exchange"
                        formControlName='internalExchange' [required]="false"
                        (change)="onCheckboxChange('internalExchange'); checkIfReasonSelectedErrorExists()">
                    <label class="wrap wrap-check" for="internal_exchange">Internal exchange</label>
                </span>
            </div>

            <p class="modal-link"><i class="fas fa-table"></i>First year unscheduled exchange premium from above source(s)</p>
        </section>
	</form>
</div>
