import { Component, OnInit, Injector, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DisableStyle } from '@ng/lfg-calendar';
import { UserDetailsService } from 'src/app/shared/services/user-details.service';
import { StateOptions, UnderwritingClassOptions, TimePeriodOptions, InflationElectionOptions, BasicIllustrationFields, Products } from 'src/config/constants';
import {
  dobValidator,
  numberRangeValidator,
  numericValidator
} from 'src/app/shared/validators/validator';
import { ProductDetailsService } from 'src/app/shared/services/product-details.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { DefaultPageload } from 'src/app/shared/services/defaultPageload.service';

@Component({
  selector: 'app-illustration-basic-info',
  templateUrl: './illustration-basic-info.component.html',
  styleUrls: ['./illustration-basic-info.component.scss']
})
export class IllustrationBasicInfoComponent implements OnInit {

  illustrationForm: FormGroup;
  formBuilder!: FormBuilder
  dateConfig = {
    id: 'DOB',
    minDate: new Date('01/01/1900'),
    maxDate: new Date(),
    defaultDate: null,
    placeholder: 'Birth Date',
    disableStyle: DisableStyle.locked,
  };
  setTimeFocus = null;
  calculatedAge: number;
  product: string = ""
  ageErr: boolean = false
  ageErrMsg: string = ""
  underwritingClassOptions = UnderwritingClassOptions
  timePeriodOptions = TimePeriodOptions
  inflationElectionOptions = InflationElectionOptions
  products = Products

  formValid = true;
  formHasNoErrors = true;

  stateOptions = StateOptions

  numInpBoxConfig = {
    counter: 1,
    defaultVal: '40',
    maxValue: '100',
    minValue: '40',
    allowNeg: false,
    currentVal: '40'
  };

  @Output() formStatus: EventEmitter<any> = new EventEmitter();

  constructor(private injector: Injector, private userService: UserDetailsService,
    private utilsService: UtilsService, private productService: ProductDetailsService,
    private defaultPageLoadService: DefaultPageload
    ) {
    this.formBuilder = this.injector.get<FormBuilder>(FormBuilder);
  }

  ngOnInit(): void {
    this.product = this.productService.getMarketName()
    this.buildForm();
    this.updateFormData();
    setTimeout(() => {
      this.disableFields();
    }, 100);
    this.defaultPageLoadService.logPageLoad();
  }

  buildForm() {
    const controls = this.getRelevantControls()
    this.illustrationForm = this.formBuilder.group(controls)
    this.basicInfoValueChanges();
  }

  onRadioChange(e) {
    if (e.target.name == "backdate") {
      e.target.value === "manual" ? this.illustrationForm.get('backdateMonths').enable() : this.illustrationForm.get('backdateMonths').disable();
    }
  }

  validateOnFocus(formElement, type): any {
    this.setTimeFocus = setTimeout(() => {
      if (type === 'in') {
        this.illustrationForm.get(formElement)?.markAsUntouched();
      } else {
        this.illustrationForm.get(formElement)?.markAsTouched();
      }
    }, 10);
  }

  onCheckboxChange(formElement): void {
    const checked = this.illustrationForm.get(formElement).value;
    if (formElement === "alternateCost") {
      if (checked) {
        this.illustrationForm.get("alternateCostAmt").enable();
      } else {
        this.illustrationForm.get("alternateCostAmt").disable();
      }
    } else if (formElement === "fundBTR") {
      if (checked) {
        this.illustrationForm.get("fundBTRAge").enable();
        this.illustrationForm.get("fundBTRAmt").enable();
        this.illustrationForm.get("btrDropdown").enable();
      } else {
        this.illustrationForm.get("fundBTRAge").disable();
        this.illustrationForm.get("fundBTRAmt").disable();
        this.illustrationForm.get("btrDropdown").disable();
      }
    }
  }

  checkIfReasonSelectedErrorExists(): void {
    console.log("Reason selected error")
  }

  handleSelectionChange(_formElement, _ind): void {
    console.log("selection changed");
  }

  numberBoxEvent(emitObj: any, formObj: any): void {
    formObj.setValue(emitObj.inpVal);
  }

  onInputBTRAge(eve): void {
    const inputObj = JSON.parse(JSON.stringify(this.numInpBoxConfig));
    inputObj.currentVal = eve.target.value;
    this.numInpBoxConfig = inputObj;
  }

  updateFormData(): void {
    const gender = this.userService.getInsuredGender()?.split('{')[0];
    const age = this.userService.getInsuredAge();
    this.illustrationForm.patchValue({
      ['firstName']: this.userService.getInsuredFirstName(),
      ['lastName']: this.userService.getInsuredLastName(),
      ['DOB']: this.utilsService.formatDateToMMDDYYYY(this.userService.getInsuredDOB()),
      ['age']: age,
      ['gender']: gender,
    });
    this.checkAgeErr(age)
  }

  disableFields(): void {
    const fieldsToDisable = ['alternateCostAmt', 'fundBTRAmt', 'fundBTRAge', 'btrDropdown', 'firstName', 'lastName', 'DOB', 'age', 'gender', 'backdateMonths'];
    fieldsToDisable.forEach(e => {
      const field = this.illustrationForm.get(e)
      if (field) {
        field.disable()
      }
    });
  }

  private basicInfoValueChanges(): any {
    let setTime = setTimeout(() => {
      this.basicInfoCheckFormStatus();
    }, 200);
    this.illustrationForm.valueChanges.subscribe((_val) => {
      clearTimeout(setTime);
      setTime = setTimeout(() => {
        this.basicInfoCheckFormStatus();
      }, 200);
    });
  }

  private basicInfoCheckFormStatus(): any {
    const obj = {
      formValid: this.illustrationForm.valid,
      formHasNoErrors: this.formHasNoErrors,
      questions: {},
      formId: 'illustrationBasicInfoForm',
      isFormChange: this.illustrationForm.dirty
    };
    this.formStatus.emit(obj);
  }

  private checkAgeErr(age): any {
    const ages = {
      [this.products.MGFA]: { min: 40, max: 80 },
      [this.products.MGMA22]: { min: 30, max: 70 },
      [this.products.MGMA24]: { min: 30, max: 70 },
      [this.products.MGII]: { min: 40, max: 79 },
      [this.products.MGMA]: { min: 30, max: 70 },
    }

    const min = ages[this.product]?.min
    const max = ages[this.product]?.max

    if (age < min || age > max) {
      this.ageErr = true
      this.ageErrMsg = `Allowable Range ${min} - ${max}`
    }
  }

  getRelevantControls(): any {
    const allControls = {
      firstName: ['', []],
      lastName: ['', []],
      DOB: ['', [dobValidator()]],
      age: ['', [numericValidator()]],
      gender: ['', []],
      underwritingClass: [this.underwritingClassOptions[0], []],
      backdate: ['', []],
      backdateMonths: ['', [numberRangeValidator(1, 11)]],
      incPremium: ['', []],
      costDisclosure: ['', []],
      printIRR: ['', []],
      printSignature: ['', []],
      revisedProjection: ['', []],
      revisedNAIC: ['', []],
      inflationElection: ['', []],
      altAge: ['', [numericValidator()]],
      additionalCostsState: ['', []],
      alternateCost: ['', []],
      alternateCostAmt: ['', [numericValidator()]],
      fundBTR: ['', []],
      fundBTRAmt: ['', [numericValidator()]],
      fundBTRAge: ['', []],
      btrDropdown: [this.timePeriodOptions[0], []]
    }

    let fields = []
    if (this.product === this.products.MGFA) {
      fields = BasicIllustrationFields.MGFA
    }
    else if (this.product === this.products.MGMA22 || this.product === this.products.MGMA24) {
      fields = BasicIllustrationFields.MGMA22
    }
    else if (this.product === this.products.MGII) {
      fields = BasicIllustrationFields.MGII
    }
    else if (this.product === this.products.MGMA) {
      fields = BasicIllustrationFields.MGMA
    }

    let controls = {}
    fields.forEach(e => {
      controls[e] = allControls[e]
    })

    return controls

  }

}
